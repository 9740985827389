import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { Box, Card, Text } from 'rebass/styled-components'
import { useWeb3React } from "@web3-react/core"
import { FactoryService } from "../../services/FactoryService"
import { supportedChain } from "../../utils"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import Common1 from '../../assets/images/nft/common1.webp'
import Common2 from '../../assets/images/nft/common2.webp'
import Rare1 from '../../assets/images/nft/rare1.webp'
import Rare2 from '../../assets/images/nft/rare2.webp'

// upCake
import Common1Cake from '../../assets/images/nft/common1cake.webp'
import Rare1Cake from '../../assets/images/nft/rare1cake.webp'

// upMatic
import Common1Matic from '../../assets/images/nft/common1matic.webp'
import Rare1Matic from '../../assets/images/nft/rare1matic.webp'


import { lighten, darken } from 'polished'
import UpgradeModal from "../../components/UpgradeModal"
import { ForgeService } from "../../services/ForgeService"

// import { StakingService } from "../../services/StakingService"
// import BigNumber from 'bignumber.js'
// import { getBalanceNumber } from "../../utils/formatBalance"

const PageWrapper = styled.div`
    padding-top: 1.5em;

 ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top:0em;
 `};
`

export const Option = styled.button<{ active: boolean }>`
  align-items: center;
  margin-right: 5px;
  font-size:1em;
  font-weight: ${({ active, theme }) => (active ? '600' : '500')};
  padding: 0.75em 0.75em 0.8em 0.75em;
  border-radius: 1em; 
  border: 0px;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? '' : 'none')};
  background-color: ${({ active, theme }) => (active ? theme.primary1 : 'black')};
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text2)};
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }`

const NFTWrapper = styled.div`
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0.15em;
    border-radius: 1em;
    grid-gap: 0.5em;
    background-color: ${({ theme }) => lighten(0.07, theme.bg1)};
    float:left;
    width: 100%;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
    width: 100%;
    `}
`

const OuterWrapper = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:2em;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
    width: 100%;
  `}
`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 72em;
    margin:auto;
 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;
 `};
`

const SmallCard = (props: any) => (
  <Card
    paddingTop='10px'
    textAlign='center'
    mb={2}
    mx={1}
    borderRadius={18}
    css={{ overflow: 'hidden', position: 'relative' }}
    {...props}
  />
)

const Name = (props: any) => (
  <Text
    as="h3"
    my={0}
    fontSize={2}
    fontFamily="sans-serif"
    textAlign="center"
    fontWeight="bold"
    {...props}
  />
)


// const ColumnLayout = (props:any) => (
//   <Flex
//     flexDirection="column"
//     flexWrap="wrap"
//     alignItems="center"
//     mx="auto"
//     css={{
//       height: '650px',
//       '& > div:nth-child(3)': {
//         marginTop: '40px'
//       }
//     }}
//     {...props}
//   />
// )
export const Upgrade = () => {
  const { account, library, chainId } = useWeb3React();
  const [modalUpgradeOpen, setUpgradeModalOpen] = useState<boolean>(false)
  const toggleUpgradeModal = () => {
    setUpgradeModalOpen(!modalUpgradeOpen)
  }

  const [artId, setArtId] = useState("");

  // const [ otherUpcake1Size, setOtherUpcake1Size ] = useState(0);
  // const [ otherUpcake1ImageUrl, setOtherUpcake1ImageUrl ] = useState("");

  const [ commonUpmatic1ImageUrl, setCommonUpmatic1ImageUrl] = useState("");
  const [ commonUpmatic1Size, setCommonUpmatic1Size ] = useState(0);
  const [ rareUpmatic1ImageUrl, setRareUpmatic1ImageUrl] = useState("");
  const [ rareUpmatic1Size, setRareUpmatic1Size ] = useState(0);
  const [ commonUpmaticUpgrade1Size, setCommonUpmaticUpgrade1Size] = useState(0);

  const [ commonUpcake1Size, setCommonUpcake1Size ] = useState(0);
  const [ commonUpcake1ImageUrl, setCommonUpcake1ImageUrl ] = useState("");
  const [ rareUpcake1Size, setRareUpcake1Size ] = useState(0);
  const [ rareUpcake1ImageUrl, setRareUpcake1ImageUrl ] = useState("");

  const [ commonUpbnb1Size, setCommonUpbnb1Size ] = useState(0);
  const [ commonUpbnb2Size, setCommonUpbnb2Size ] = useState(0);

  const [ commonUpbnb1ImageUrl, setCommonUpbnb1ImageUrl ] = useState("");
  const [ commonUpbnb2ImageUrl, setCommonUpbnb2ImageUrl ] = useState("");

  const [ rareUpbnb1Size, setRareUpbnb1Size ] = useState(0);
  const [ rareUpbnb2Size, setRareUpbnb2Size ] = useState(0);

  const [ rareUpbnb1ImageUrl, setRareUpbnb1ImageUrl ] = useState("");
  const [ rareUpbnb2ImageUrl, setRareUpbnb2ImageUrl ] = useState("");

  const [commonUpbnbUpgrade1Size, setCommonUpbnbUpgrade1Size] = useState(0);
  const [commonUpbnbUpgrade2Size, setCommonUpbnbUpgrade2Size] = useState(0);

  const [commonUpcakeUpgrade1Size, setCommonUpcakeUpgrade1Size] = useState(0);
  // const [rareUpcakeUpgrade1Size, setRareUpcakeUpgrade1Size] = useState(0);

  // const [ other1Size, setOther1Size ] = useState(0);

  // // Common Owned Size
  // const [common1Size, setCommon1Size] = useState(0);
  // const [common2Size, setCommon2Size] = useState(0);

  // const [commonUpgrade1Size, setCommonUpgrade1Size] = useState(0);
  // const [commonUpgrade2Size, setCommonUpgrade2Size] = useState(0);

  // // Common Image Url
  // const [ other1ImageUrl, setOther1ImageUrl ] = useState("");
  // const [common1ImageUrl, setCommon1ImageUrl] = useState("");
  // const [common2ImageUrl, setCommon2ImageUrl] = useState("");

  // const [otherUpgrade1Size, setOtherUpgrade1Size] = useState(0);

  // // Rare Owned Size
  // const [rare1Size, setRare1Size] = useState(0);
  // const [rare2Size, setRare2Size] = useState(0);

  // // Rare Owned Size
  // const [rareUpgrade1Size, setRareUpgrade1Size] = useState(0);
  // const [rareUpgrade2Size, setRareUpgrade2Size] = useState(0);
  
  // // Rare Image Url
  // const [rare1ImageUrl, setRare1ImageUrl] = useState("");
  // const [rare2ImageUrl, setRare2ImageUrl] = useState("");

  const upgradeNft = (id: any) => {
    setArtId(id)
    toggleUpgradeModal()
  }

  const NFTCard = (props: any) => (
    <SmallCard>

      <LazyLoadImage
        alt={"NFT"}
        effect="blur"
        src={props.image}
        width={150}
        height={200} >
      </LazyLoadImage>

      <Box py={3} px={2}>
        <Name>Owned : {props.owned}</Name>
        <Name>Upgrade Cost : {props.upgradeCost}</Name>
        <Name>{props.title}</Name>

        <br />

        <Option active={props.type == "common" && (props.owned >= props.upgradeCost)} onClick={() => upgradeNft(props.id)}>
          Upgrade
        </Option>
      </Box>
    </SmallCard>
  )

  useEffect(() => {
    if (account && supportedChain(chainId)) {
      const getBalance = async () => {
        const contract = new FactoryService(library, account, chainId!);
        const forgeContract = new ForgeService(library, account, chainId!);
        // BSC
        if (chainId == 56) {
          const common1 = await contract.balanceOf("56042"); //upBNB MGE (CZ)
          const common2 = await contract.balanceOf("170"); //Flying Carpet 
          setCommonUpbnb1Size(common1.toNumber());
          setCommonUpbnb2Size(common2.toNumber());

          const rare1 = await contract.balanceOf("56069"); //upBNB MGE (CZ)
          const rare2 = await contract.balanceOf("70"); //Flying Carpet
          setRareUpbnb1Size(rare1.toNumber());
          setRareUpbnb2Size(rare2.toNumber());

          const commonUpcake1 = await contract.balanceOf("56142"); //upCake MGE
          setCommonUpcake1Size(commonUpcake1.toNumber());

          const rareUpcake1 = await contract.balanceOf("56169"); //upCake MGE
          setRareUpcake1Size(rareUpcake1.toNumber());

          // const otherUpcake1 = await contract.balanceOf("56269"); //upCake MGE (CZ)
          // setOtherUpcake1Size(otherUpcake1.toNumber());

          const upgradeCostCommon1 = await forgeContract.getUpgradeCost("56042");
          const upgradeCostCommon2 = await forgeContract.getUpgradeCost("170");
          // const upgradeCostRare1 = await forgeContract.getUpgradeCost("56069");
          // const upgradeCostRare2 = await forgeContract.getUpgradeCost("70");
          setCommonUpbnbUpgrade1Size(upgradeCostCommon1.toNumber());
          setCommonUpbnbUpgrade2Size(upgradeCostCommon2.toNumber());

          const upgradeUpcakeCostCommon1 = await forgeContract.getUpgradeCost("56142");
          setCommonUpcakeUpgrade1Size(upgradeUpcakeCostCommon1.toNumber());
        }
        else if(chainId == 137){
          const common1 = await contract.balanceOf("169"); //upMatic Flying Carpet
          setCommonUpmatic1Size(common1.toNumber());
          const rare1 = await contract.balanceOf("69"); //upMatic Flying Carpet
          setRareUpmatic1Size(rare1.toNumber());
          const upgradeUpmaticCostCommon1 = await forgeContract.getUpgradeCost("169");
          setCommonUpmaticUpgrade1Size(upgradeUpmaticCostCommon1.toNumber());
          console.log("Upgrade cost : ",upgradeUpmaticCostCommon1.toNumber());

        }
        // else if (chainId == 1) {
        //   // const common1 = await contract.balanceOf("0");
        //   const common2 = await contract.balanceOf("169");

        //   setCommon1Size(0);
        //   setCommon2Size(common2.toNumber());

        //   setRare1Size(0);
        //   setRare2Size(0);
        // }
      };
      getBalance();
      
      if(chainId == 56){
        setCommonUpbnb1ImageUrl(Common1);
        setCommonUpbnb2ImageUrl(Common2);
        setRareUpbnb1ImageUrl(Rare1);
        setRareUpbnb2ImageUrl(Rare2);

        setCommonUpcake1ImageUrl(Common1Cake);
        setRareUpcake1ImageUrl(Rare1Cake);
        // setOtherUpcake1ImageUrl(Other1Cake);
      }      
      else if(chainId == 137){
        setCommonUpmatic1ImageUrl(Common1Matic);
        setRareUpmatic1ImageUrl(Rare1Matic);
      }
      // else if(chainId == 1){
      //   setCommon1ImageUrl(Common1);
      //   setCommon2ImageUrl(Common2);
      //   setRare1ImageUrl(Rare1);
      //   setRare2ImageUrl(Rare2);
      // }
    }
  }, [library, account, chainId]);

  return (
    <PageWrapper>
      <PrimaryCard width="auto" margin="auto">
        <CardContent>
          <Title>
            Upgrade
          </Title>

          {(() => {
            if (account) {
              // BSC 
              if (chainId == 56) {
                return (
                  <OuterWrapper>
                    <NFTWrapper>
                      <NFTCard title={"CZ Pixel Art (Common)"} image={commonUpbnb1ImageUrl} owned={commonUpbnb1Size} id={"56042"} upgradeCost={commonUpbnbUpgrade1Size} type={"common"}>
                      </NFTCard>

                      <NFTCard title={"CZ Pixel Art (Rare)"} image={rareUpbnb1ImageUrl} owned={rareUpbnb1Size} id={"56069"} upgradeCost={0} type={"rare"}>
                      </NFTCard>
                    </NFTWrapper> 

                    <NFTWrapper>
                      <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpbnb2ImageUrl} owned={commonUpbnb2Size} id={"170"} upgradeCost={commonUpbnbUpgrade2Size} type={"common"}>
                      </NFTCard>

                      <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpbnb2ImageUrl} owned={rareUpbnb2Size} id={"70"} upgradeCost={0} type={"rare"}>
                      </NFTCard>
                    </NFTWrapper> 

                    <NFTWrapper>
                      <NFTCard title={"Cake Pixel Art (Common)"} image={commonUpcake1ImageUrl} owned={commonUpcake1Size} id={"56142"} upgradeCost={commonUpcakeUpgrade1Size} type={"common"}>
                      </NFTCard>

                      <NFTCard title={"Cake Pixel Art (Rare)"} image={rareUpcake1ImageUrl} owned={rareUpcake1Size} id={"56169"} upgradeCost={0} type={"rare"}>
                      </NFTCard>
                    </NFTWrapper>
                  </OuterWrapper>
                )
              }
              else if (chainId == 137) {
                return (
                  <OuterWrapper>
                    <NFTWrapper>
                      <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpmatic1ImageUrl} owned={commonUpmatic1Size} id={"69"} upgradeCost={commonUpmaticUpgrade1Size} type={"common"}>
                      </NFTCard>

                      <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpmatic1ImageUrl} owned={rareUpmatic1Size} id={"169"} upgradeCost={0} type={"rare"}>
                      </NFTCard>
                    </NFTWrapper> 
                  </OuterWrapper>
                )
              }
              // else if (chainId == 1) {
              //   return (
              //     <div>
              //       <div>
              //         <label>Common Art</label>
              //         <NFTWrapper>
              //           <NFTCard title={"Flying Carpet Art"} image={common2ImageUrl} owned={common2Size}>
              //           </NFTCard>
              //         </NFTWrapper>
              //       </div>
              //     </div>
              //   )
              // }
              else{
                return <Title>Chain not supported</Title>
              }
            } else {
              return (<Text>{'Connect to a wallet'}</Text>)
            }

            return null;
          })()}
        </CardContent>
      </PrimaryCard>

      <UpgradeModal
        modalOpen={modalUpgradeOpen}
        toggleInfoModal={toggleUpgradeModal}
        artId={artId}
      />
    </PageWrapper>
  )
}