import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { Box, Card, Text } from 'rebass/styled-components'
import { useWeb3React } from "@web3-react/core"
import { FactoryService } from "../../services/FactoryService"
import { supportedChain } from "../../utils"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

// upBNB
import Common1 from '../../assets/images/nft/common1.webp'
import Common2 from '../../assets/images/nft/common2.webp'
import Rare1 from '../../assets/images/nft/rare1.webp'
import Rare2 from '../../assets/images/nft/rare2.webp'

// upCake
import Common1Cake from '../../assets/images/nft/common1cake.webp'
import Rare1Cake from '../../assets/images/nft/rare1cake.webp'
import Other1Cake from '../../assets/images/nft/other1cake.webp'

// upMatic
import Common1Matic from '../../assets/images/nft/common1matic.webp'
import Rare1Matic from '../../assets/images/nft/rare1matic.webp'
// import Common from '../../assets/images/nft/common.webp'
// import Rare from '../../assets/images/nft/rare.webp'
import { lighten, darken } from 'polished'
import { AirdropService } from "../../services/AirdropService"
import { AirdropServiceV1 } from "../../services/AirdropServiceV1"
import { AIRDROP_BSC_ADDRESS_CAKE_COMMON,  AIRDROP_BSC_ADDRESS_CAKE_SPACE} from "../../constants"
// import { useUpToken } from "../../contexts/UpTokenContext"
// import { Tween, Timeline } from 'react-gsap';

const PageWrapper = styled.div`
    padding-top: 1.5em;

 ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top:0em;
 `};
`

const NFTWrapper = styled.div`
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.15em;
    border-radius: 1em;
    grid-gap: 0.5em;
    background-color: ${({ theme }) => lighten(0.07, theme.bg1)};
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
    `}
`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 52em;
    margin:auto;
 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;
 `};
`

export const Option = styled.button<{ active: boolean }>`
  align-items: center;
  margin-right: 5px;
  font-size:1em;
  font-weight: ${({ active, theme }) => (active ? '600' : '500')};
  padding: 0.75em 0.75em 0.8em 0.75em;
  border-radius: 1em; 
  border: 0px;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? '': 'none')};
  background-color: ${({ active, theme }) => (active ? theme.primary1 :  'black')};
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text2)};
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }`


const SmallCard = (props:any) => (
  <Card 
      paddingTop='10px'
      textAlign='center'
      mb={2}
      mx={1}
      borderRadius={18}
      css={{ overflow: 'hidden', position: 'relative' }}
      {...props}
  />
  )

const Name = (props:any) => (
    <Text
      as="h3"
      my={0}
      fontSize={2}
      fontFamily="sans-serif"
      textAlign="center"
      fontWeight="bold"
      {...props}
    />
  )

  // const ColumnLayout = (props:any) => (
  //   <Flex
  //     flexDirection="column"
  //     flexWrap="wrap"
  //     alignItems="center"
  //     mx="auto"
  //     css={{
  //       height: '650px',
  //       '& > div:nth-child(3)': {
  //         marginTop: '40px'
  //       }
  //     }}
  //     {...props}
  //   />
  // )
export const MyNFT = () => {
    // const { upToken } = useUpToken();
    
    const { account, library, chainId } = useWeb3React();

    // MATIC
    const [ commonUpmatic1ImageUrl, setCommonUpmatic1ImageUrl] = useState("");
    const [ commonUpmatic1Size, setCommonUpmatic1Size ] = useState(0);
    const [ isClaimCommon1Upmatic, setClaimCommon1Upmatic] = useState(false);
    const [ rareUpmatic1ImageUrl, setRareUpmatic1ImageUrl] = useState("");
    const [ rareUpmatic1Size, setRareUpmatic1Size ] = useState(0);
    const [ isClaimRare1Upmatic, setClaimRare1Upmatic] = useState(false);
    // END MATIC

    // CAKE
    const [ other1UpcakeSize, setOtherUpcake1Size ] = useState(0);
    const [ other1UpcakeImageUrl, setOtherUpcake1ImageUrl ] = useState("");

    const [ commonUpcake1Size, setCommonUpcake1Size ] = useState(0);
    const [ commonUpcake1ImageUrl, setCommonUpcake1ImageUrl ] = useState("");

    const [ rareUpcake1Size, setRareUpcake1Size ] = useState(0);
    const [ rareUpcake1ImageUrl, setRareUpcake1ImageUrl ] = useState("");

    const [ isClaimOtherUpcake, setClaimOtherUpcake] = useState(false);
    // END CAKE

    // BNB
    const [ commonUpbnb1Size, setCommonUpbnb1Size ] = useState(0);
    const [ commonUpbnb2Size, setCommonUpbnb2Size ] = useState(0);

    const [ commonUpbnb1ImageUrl, setCommonUpbnb1ImageUrl ] = useState("");
    const [ commonUpbnb2ImageUrl, setCommonUpbnb2ImageUrl ] = useState("");

    const [ rareUpbnb1Size, setRareUpbnb1Size ] = useState(0);
    const [ rareUpbnb2Size, setRareUpbnb2Size ] = useState(0);

    const [ rareUpbnb1ImageUrl, setRareUpbnb1ImageUrl ] = useState("");
    const [ rareUpbnb2ImageUrl, setRareUpbnb2ImageUrl ] = useState("");

    const [ isClaimCommon1Upbnb, setClaimCommon1Upbnb] = useState(false);
    const [ isClaimCommon2Upbnb, setClaimCommon2Upbnb] = useState(false);
    const [ isClaimCommonUpcake, setClaimCommonUpcake] = useState(false);
    // END BNB 

    // ETH
    const [ common2Size, setCommon2Size ] = useState(0);
    const [ common2ImageUrl, setCommon2ImageUrl ] = useState("");
    // END ETH
    const NFTCard = (props:any) => (
      <SmallCard>

        <LazyLoadImage
          alt={"NFT"}
          effect="blur"
          src={props.image}
          width={150}
          height={200} >
          {/* <Timeline 
            target={
              <img src={props.image} />
              }>
            <Tween 
              staggerFrom={{ opacity: 0, y: '-20px' }} 
              staggerTo={{ opacity: 1, y: '0px' }} 
              stagger={0.12} 
              duration={1} 
              position="+=0" />
          </Timeline> */}
      </LazyLoadImage>

        {/* <Image src={props.image}></Image>   */}
        <Box py={3} px={2}>
          <Name>Owned : {props.owned}</Name>
          <Name>{props.title}</Name>

          <br/>

          <Option active={props.claimable} onClick={() => claimNft(props.id)}>
              Claim
          </Option>
        </Box>
      </SmallCard>
    )
    const claimNft = async (id:any) => {
      if(chainId == 1){
        if(id == "169"){
          const airdropService = new AirdropService(library, account!, chainId!, "169");
          await airdropService.claim();
        }
      }
      else if(chainId == 56){
        if(id == "56042"){
          const airdropUpbnbCommon1Service = new AirdropService(library, account!, chainId!, "56042");
          await airdropUpbnbCommon1Service.claim();
        }
        else if(id == "170"){
          const airdropUpbnbCommon1Service = new AirdropService(library, account!, chainId!, "170");
          await airdropUpbnbCommon1Service.claim();
        }
        else if(id == "56142"){
          const airdropUpcakeCommonService = new AirdropServiceV1(library, account!, chainId!, AIRDROP_BSC_ADDRESS_CAKE_COMMON);
          await airdropUpcakeCommonService.claim();
        }
        else if(id == "56269"){
          const airdropUpcakeSpaceService = new AirdropServiceV1(library, account!, chainId!, AIRDROP_BSC_ADDRESS_CAKE_SPACE);
          await airdropUpcakeSpaceService.claim();
        }
      }
      else if(chainId == 137){
        const airdropService = new AirdropServiceV1(library, account!, chainId!, "0x46E378e9812A568780066611782bc90bF0247bE4");
        await airdropService.claim();
        // if(id == "169"){
        //   const airdropService = new AirdropServiceV1(library, account!, chainId!, "169");
        //   await airdropService.claim();
        // }
        // else if(id == "69"){
        //   const airdropService = new AirdropServiceV1(library, account!, chainId!, "69");
        //   await airdropService.claim();
        // }
      }
    }
    
    useEffect(() => {
      if(account && supportedChain(chainId)) {
        if(chainId == 56){
          const airdropUpbnbCommon1Service = new AirdropService(library, account, chainId!, "56042");
          const airdropUpbnbCommon2Service = new AirdropService(library, account, chainId!, "170");
          const airdropUpcakeCommonService = new AirdropServiceV1(library, account, chainId!, AIRDROP_BSC_ADDRESS_CAKE_COMMON);
          const airdropUpcakeSpaceService = new AirdropServiceV1(library, account, chainId!, AIRDROP_BSC_ADDRESS_CAKE_SPACE);
          const checkEligible = async() => {
            setClaimCommonUpcake(await airdropUpcakeCommonService.isEligible());
            setClaimOtherUpcake(await airdropUpcakeSpaceService.isEligible());
            setClaimCommon1Upbnb(await airdropUpbnbCommon1Service.isEligible());
            setClaimCommon2Upbnb(await airdropUpbnbCommon2Service.isEligible());
          }
          checkEligible();
        }
        else if(chainId == 137){
          const airdropUpmaticCommon1Service = new AirdropService(library, account, chainId!, "169");
          const airdropUpmaticRare1Service = new AirdropService(library, account, chainId!, "69");
          const checkEligible = async() => {
            setClaimCommon1Upmatic(await airdropUpmaticCommon1Service.isEligible());
            setClaimRare1Upmatic(await airdropUpmaticRare1Service.isEligible());
          }
          checkEligible();
        }
        // setClaimCommonUpcake(true);
        // setClaimOtherUpcake(true);
      }
    },  [library, account, chainId]);
    useEffect(() => {
        if(account && supportedChain(chainId)) {
            const getBalance = async () => {
                const contract = new FactoryService(library, account, chainId!);

                // BSC
                if(chainId == 56){
                  const common1 = await contract.balanceOf("56042"); //upBNB MGE (CZ)
                  const common2 = await contract.balanceOf("170"); //Flying Carpet (common)
                  setCommonUpbnb1Size(common1.toNumber());
                  setCommonUpbnb2Size(common2.toNumber());

                  const rare1 = await contract.balanceOf("56069"); //upBNB MGE (CZ)
                  const rare2 = await contract.balanceOf("70"); //Flying Carpet
                  setRareUpbnb1Size(rare1.toNumber());
                  setRareUpbnb2Size(rare2.toNumber());

                  const commonUpcake1 = await contract.balanceOf("56142"); //upCake MGE (CZ)
                  setCommonUpcake1Size(commonUpcake1.toNumber());

                  const rareUpcake1 = await contract.balanceOf("56169"); //upCake MGE (CZ)
                  setRareUpcake1Size(rareUpcake1.toNumber());

                  const other1 = await contract.balanceOf("56269"); //space cake
                  // const rare2 = await contract.balanceOf("70"); //Flying Carpet
                  setOtherUpcake1Size(other1.toNumber());
                }
                else if(chainId == 137){
                  const common1 = await contract.balanceOf("169"); //upMatic Flying Carpet
                  setCommonUpmatic1Size(common1.toNumber());
                  const rare1 = await contract.balanceOf("69"); //upMatic Flying Carpet
                  setRareUpmatic1Size(rare1.toNumber());
                }
                else if(chainId == 1){
                    const common2 = await contract.balanceOf("169");
                    setCommon2Size(common2.toNumber());
                }
            };
            getBalance();


            if(chainId == 56){
              setCommonUpbnb1ImageUrl(Common1);
              setCommonUpbnb2ImageUrl(Common2);
              setRareUpbnb1ImageUrl(Rare1);
              setRareUpbnb2ImageUrl(Rare2);

              setCommonUpcake1ImageUrl(Common1Cake);
              setRareUpcake1ImageUrl(Rare1Cake);
              setOtherUpcake1ImageUrl(Other1Cake);
            }
            else if(chainId == 137){
              setCommonUpmatic1ImageUrl(Common1Matic);
              setRareUpmatic1ImageUrl(Rare1Matic);
            }
            else if(chainId == 1){
            //   setCommon1ImageUrl(Common1);
              setCommon2ImageUrl(Common2);
            //   setRare1ImageUrl(Rare1);
            //   setRare2ImageUrl(Rare2);
            }
        }
    },  [library, account, chainId]);

    return (
        <PageWrapper>
            <PrimaryCard width="auto" margin="auto">
                <CardContent>
                  <Title>
                    My NFT
                  </Title>

                  {(() => {
                    if (account){
                        // BSC 
                        if(chainId == 56){
                          return (
                              <NFTWrapper>
                                      <NFTCard title={"CZ Pixel Art (Common)"} image={commonUpbnb1ImageUrl} owned={commonUpbnb1Size} claimable={isClaimCommon1Upbnb} id="56042">
                                      </NFTCard>

                                      <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpbnb2ImageUrl} owned={commonUpbnb2Size} claimable={isClaimCommon2Upbnb} id="170">
                                      </NFTCard>

                                      <NFTCard title={"CZ Pixel Art (Rare)"} image={rareUpbnb1ImageUrl} owned={rareUpbnb1Size} claimable={false}>
                                      </NFTCard>

                                      <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpbnb2ImageUrl} owned={rareUpbnb2Size} claimable={false}>
                                      </NFTCard>

                                      <NFTCard title={"Cake Pixel Art (Common)"} id={"56142"} image={commonUpcake1ImageUrl} owned={commonUpcake1Size} claimable={isClaimCommonUpcake}>
                                      </NFTCard>

                                      <NFTCard title={"Cake Pixel Art (Rare)"} image={rareUpcake1ImageUrl} owned={rareUpcake1Size} claimable={false}>
                                      </NFTCard>

                                      <NFTCard title={"Space Art"} id={"56269"} image={other1UpcakeImageUrl} owned={other1UpcakeSize} claimable={isClaimOtherUpcake}>
                                      </NFTCard>
                              </NFTWrapper>
                          )
                        }
                        else if(chainId == 137){
                          return (
                              <NFTWrapper>
                                      <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpmatic1ImageUrl} owned={commonUpmatic1Size} claimable={isClaimCommon1Upmatic}>
                                      </NFTCard>

                                      <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpmatic1ImageUrl} owned={rareUpmatic1Size} claimable={isClaimRare1Upmatic}>
                                      </NFTCard> 
                              </NFTWrapper>
                          )
                        }
                        else if(chainId == 1){
                          return (
                            <div>
                              <div>
                                <label>Common Art</label>
                                <NFTWrapper>
                                        <NFTCard title={"Flying Carpet Art"} image={common2ImageUrl} owned={common2Size}>
                                        </NFTCard>
                                  </NFTWrapper>
                              </div>
                            </div>
                          )
                        }
                        else{
                          return <Title>Chain not supported</Title>
                        }
                    } else { 
                        return (<Text>{'Connect to a wallet'}</Text>)
                    }
                  })()}
                </CardContent>
            </PrimaryCard>
        </PageWrapper>
    )
}