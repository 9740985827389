import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { darken, lighten } from 'polished'
import Row from '../Row'

export const Option = styled.button<{ active: boolean }>`
  align-items: center;
  margin-right: 5px;
  font-size:1em;
  font-weight: ${({ active, theme }) => (active ? '600' : '500')};
  padding: 0.75em 0.75em 0.8em 0.75em;
  border-radius: 1em; 
  border: 0px;
  outline: none;
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.primary1 :  'black')};
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text2)};
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }`

const Wrapper = styled.div`
    padding-top: 1em;
`

const HeaderLinks = styled(Row)`
  display: grid;
  margin:auto;
  margin-bottom:20px;
  justify-self: center;  
  width: fit-content;
  padding: 0.15em;
  border-radius: 1em;  
  grid-auto-flow: column;
  grid-gap: 1.5em;
  align-items:center;
  background-color: ${({ theme }) => lighten(0.07, theme.bg1)};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 1em;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 0.5em 0.75em 0.55em 0.75em;

  &.${activeClassName} {
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.primary1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }`


  
  export default function Navigation({ children }: { children: any }) {
      return (
          <Wrapper>
            <HeaderLinks>
                <StyledNavLink id={`swap-nav-link`} to={'/mynft'} isActive={(match, location) => location.pathname.toLowerCase() === "/mynft" ||  location.pathname.toLowerCase() === "/"}>
                    My NFT
                </StyledNavLink>
                <StyledNavLink id={`stake-nav-link`} to={'/stake'}>
                    Stake NFT
                </StyledNavLink>
                <StyledNavLink id={`stake-nav-link`} to={'/upgrade'}>
                    Upgrade NFT
                </StyledNavLink>
                <StyledNavLink id={`stake-nav-link`} to={'/sharft'}>
                    SHARFT
                </StyledNavLink>
            </HeaderLinks>

            {children}
          </Wrapper>   
      )
  }