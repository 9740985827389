import React from "react"

export const Airdrop = () => {

    return (
        <div>

        {/* <TransactionCompletedModal title={"NFT Claim success"} hash={transactionHash} isOpen={status === ClaimStatus.Claimed} onDismiss={() => setStatus(ClaimStatus.None)} />
        <ClaimButton disabled={claming || !eligible || !supportedChain(chainId)} onClick={claim}>
            {
                claming ? <PendingContent text={"Claiming..."}/> : 
                chainId == 56 && upToken == "" ? "Select upToken first" :
                !eligible ? `Nothing to claim` : `Claim NFT`
            }
        </ClaimButton> */}
        </div>
    )
}