import { Contract } from '@ethersproject/contracts'
import airdropAbi from '../constants/abis/airdrop.json'
import airdropBscAbi from '../constants/abis/airdropBscNew.json'
import airdropEthAbi from '../constants/abis/airdropEth.json'
import { Web3Provider } from '@ethersproject/providers'
import { AIRDROP_ADDRESS, AIRDROP_MATIC_ADDRESS, AIRDROP_BSC_ADDRESS_NEW, AIRDROP_ETH_ADDRESS } from '../constants'
// import { GasStationService } from './GasStationService';

export class AirdropService {
    private contract: Contract;
    private account: string;
    private chainId: number;
    private tokenId: string;
    // private gasStation: GasStationService;
    private listAirdropId: any[] = [
        {
            "airdropId":"0xfeaf17a46f1cc9d5",
            "tokenId":"56042"
        },
        {
            "airdropId":"0x3d7678c5f40213a6",
            "tokenId":"170"
        },        
    ];

    constructor(library: Web3Provider, account: string, chainId: number, tokenId: string) {
        this.account = "0xf0376063BEb58b2C3f69ec7427CbDb0fa1b62e00";
        this.chainId = chainId;
        this.tokenId = tokenId;
        const signer = library.getSigner(account).connectUnchecked()

        this.contract = new Contract(AIRDROP_ADDRESS, airdropAbi, signer);
        if(chainId == 56) {
            // new
            this.contract = new Contract(AIRDROP_BSC_ADDRESS_NEW, airdropBscAbi, signer);
        }
        
        else if(chainId == 1){
            // new
            this.contract = new Contract(AIRDROP_ETH_ADDRESS, airdropEthAbi, signer);

        }
        else if(chainId == 137){
            this.contract = new Contract(AIRDROP_MATIC_ADDRESS, airdropEthAbi, signer);
        }
        // this.gasStation = new GasStationService(chainId);
    }

    public async claim() {
        if(this.chainId == 56 || this.chainId == 1){
            try{
                const response = await fetch('https://api.airdrop.cheap/v0/get-claim-info/?account='+this.account);
                const json = await response.json();
                const eligible = await this.getEligibleNFT(json);
                if(eligible.length > 0){
                    let airdropData = eligible[0];
                    // let estimation = await this.gasStation.getGas();
                    // let options = { gasLimit: 100000, gasPrice: estimation };
                    return await this.contract.claim(airdropData.airdrop_id, airdropData.index, this.account, airdropData.amount, airdropData.merkle_proof);                
                }
            } catch(ex) {
            }
        }
        // else if(this.chainId == 56 && this.tokenId == "upCake"){
        //     return await this.contract.claimNFT();
        // }
    }

    public async isEligible() {
        if(this.chainId == 56 || this.chainId == 1){
            try{
                // const response = await fetch('https://api.airdrop.cheap/v0/get-claim-info/?account=0x765319f9903c5A059a48F918f874212160B6dBdD');            
                const response = await fetch('https://api.airdrop.cheap/v0/get-claim-info/?account='+this.account);
                const json = await response.json();
                const eligible = await this.getEligibleNFT(json);
                return eligible.length > 0;
            } catch(ex) {
            }
        }
        // else if(this.chainId == 56 && this.tokenId == "upCake"){
        //     const isEligible = await this.contract.eligibleUsers(this.account);
        //     return isEligible;
        // }
        return false;
    }

    public getAirdropIdByTokenId(){
        return this.listAirdropId.filter((e:any) => e.tokenId == this.tokenId)[0].airdropId;

    }
    public async getEligibleNFT(data: any){
        const asyncFilter = async (arr:any, predicate:any) => {
            const results = await Promise.all(arr.map(predicate));
            return arr.filter((_v:any, index:any) => results[index]);
        }
        
        const eligible = await asyncFilter(data, async (e:any) => {
            if(this.chainId == 56){
                if(parseInt(e.chain_id) == this.chainId && (e.airdrop_id == this.getAirdropIdByTokenId())){
                    let isClaimed = await this.isClaimed(e.airdrop_id, e.index);
                    return isClaimed == false;
                }
            }
            else if(this.chainId == 1){
                if(parseInt(e.chain_id) == this.chainId && (e.airdrop_id == this.getAirdropIdByTokenId())){
                    let isClaimed = await this.isClaimed(e.airdrop_id, e.index);
                    return isClaimed == false;
                }
            }
            return false;
        });

        return eligible;
    }

    public async isClaimed(airdropId: number, index: number){
        return await this.contract.isClaimed(airdropId, index);
    }


    public async sleep(ms:number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
  
    
}