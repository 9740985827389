import { Contract } from '@ethersproject/contracts'
import sharftAbi from '../constants/abis/sharft.json'
import { Web3Provider } from '@ethersproject/providers'
import { 
    SHARFT_COMMON1_ADDRESS, SHARFT_COMMON2_ADDRESS, SHARFT_RARE1_ADDRESS, SHARFT_RARE2_ADDRESS,
    SHARFT_MATIC_COMMON1_ADDRESS, SHARFT_MATIC_RARE1_ADDRESS, SHARFT_COMMON1_CAKE_ADDRESS
} from '../constants'
import Common1 from '../assets/images/nft/common1.webp'
import Common2 from '../assets/images/nft/common2.webp'
import Rare1 from '../assets/images/nft/rare1.webp'
import Rare2 from '../assets/images/nft/rare2.webp'
import Common1Matic from '../assets/images/nft/common1matic.webp'
import Rare1Matic from '../assets/images/nft/rare1matic.webp'

import Common1Cake from '../assets/images/nft/common1cake.webp'
import Rare1Cake from '../assets/images/nft/rare1cake.webp'
import Other1Cake from '../assets/images/nft/other1cake.webp'

export class SharftService {

    private contractCommon1: Contract;
    private contractCommon2: Contract;
    private contractCommonCake1: Contract;
    private contractRare1: Contract;
    private contractRare2: Contract;
    private address: string;
    private chainId: number;

    constructor(library: Web3Provider, account: string, chainId: number) {
        const signer = library.getSigner(account).connectUnchecked()
        this.address = account;
        this.chainId = chainId;
        this.contractCommon1 = new Contract(SHARFT_COMMON1_ADDRESS, sharftAbi, signer);
        this.contractCommon2 = new Contract(SHARFT_COMMON2_ADDRESS, sharftAbi, signer);
        this.contractRare1 = new Contract(SHARFT_RARE1_ADDRESS, sharftAbi, signer);
        this.contractRare2 = new Contract(SHARFT_RARE2_ADDRESS, sharftAbi, signer);
        this.contractCommonCake1 = new Contract(SHARFT_COMMON1_CAKE_ADDRESS, sharftAbi, signer);
        if(chainId == 56) {
            this.contractCommon1 = new Contract(SHARFT_COMMON1_ADDRESS, sharftAbi, signer);
            this.contractCommon2 = new Contract(SHARFT_COMMON2_ADDRESS, sharftAbi, signer);
            this.contractRare1 = new Contract(SHARFT_RARE1_ADDRESS, sharftAbi, signer);
            this.contractRare2 = new Contract(SHARFT_RARE2_ADDRESS, sharftAbi, signer);
            this.contractCommonCake1 = new Contract(SHARFT_COMMON1_CAKE_ADDRESS, sharftAbi, signer);
        }
        else if(chainId ==137){
            this.contractCommon1 = new Contract(SHARFT_MATIC_COMMON1_ADDRESS, sharftAbi, signer);
            this.contractRare1 = new Contract(SHARFT_MATIC_RARE1_ADDRESS, sharftAbi, signer);
        }
    }


    public async balanceOfCommon1() {
        return await this.contractCommon1.balanceOf(this.address);
    }

    public async balanceOfCommonCake1() {
        return await this.contractCommonCake1.balanceOf(this.address);
    }

    public async balanceOfCommon2() {
        return await this.contractCommon2.balanceOf(this.address);
    }
 
    public async balanceOfRare1() {
        return await this.contractRare1.balanceOf(this.address);
    }

    public async balanceOfRare2() {
        return await this.contractRare2.balanceOf(this.address);
    }
    
    public getImageUrl(artId:string){
        if(this.chainId == 56){
            if(artId == "56042"){
                return Common1;
            }
            else if(artId == "170"){
                return Common2;
            }
            else if(artId == "56069"){
                return Rare1;
            }
            else if(artId == "70"){
                return Rare2;
            }
            else if(artId == "56142"){
                return Common1Cake;
            }
            else if(artId == "56169"){
                return Rare1Cake;
            }
            else if(artId == "56269"){
                return Other1Cake;
            }
        }
        else if(this.chainId == 137){
            if(artId == "169"){
                return Common1Matic;
            }
            else if(artId == "69"){
                return Rare1Matic;
            }
        }
        return "";
    }

    public async getBalance(artId: String){
        if(this.chainId == 56){
            if(artId == "56042"){
                return await this.balanceOfCommon1();
            }
            else if(artId == "170"){
                return await this.balanceOfCommon2();
            }
            else if(artId == "56069"){
                return await this.balanceOfRare1();
            }
            else if(artId == "70"){
                return await this.balanceOfRare2();
            }
            else if(artId == "56142"){
                return await this.balanceOfCommonCake1();
            }            
        }
        return 0;
    }

    public async shatterNFT(artId: string, amount: any){
        if(this.chainId == 56){
            if(artId == "56042"){
                return await this.contractCommon1.ShatterNFTBatch(amount);
            }
            else if(artId == "170"){
                return await this.contractCommon2.ShatterNFTBatch(amount);
            }
            else if(artId == "56069"){
                return await this.contractRare1.ShatterNFTBatch(amount);
            }
            else if(artId == "70"){
                return await this.contractRare2.ShatterNFTBatch(amount);
            }
            else if(artId == "56142"){
                return await this.contractCommonCake1.ShatterNFTBatch(amount);
            }
        }
        return 0;
    }

    public async unshatterNFT(artId: string, amount: any){
        if(this.chainId == 56){
            if(artId == "56042"){
                return await this.contractCommon1.reforgeNFTBatch(amount);
            }
            else if(artId == "170"){
                return await this.contractCommon2.reforgeNFTBatch(amount);
            }
            else if(artId == "56069"){
                return await this.contractRare1.reforgeNFTBatch(amount);
            }
            else if(artId == "70"){
                return await this.contractRare2.reforgeNFTBatch(amount);
            }
            else if(artId == "56142"){
                return await this.contractCommonCake1.reforgeNFTBatch(amount);
            }
        }
        return 0;
    }

    // public async approve(artId: string){
    //     if(this.chainId == 56){
    //         let approveValue = "9999999999999999999999";
    //         if(artId == "56042"){
    //             return await this.contractCommon1.approve(this.address, approveValue);
    //         }
    //         else if(artId == "170"){
    //             return await this.contractCommon2.approve(this.address, approveValue);
    //         }
    //         else if(artId == "56069"){
    //             return await this.contractRare1.approve(this.address, approveValue);
    //         }
    //         else if(artId == "70"){
    //             return await this.contractRare2.approve(this.address, approveValue);
    //         }
    //     }
    //     return 0;
    // }
}