import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { Box, Card, Text } from 'rebass/styled-components'
import { useWeb3React } from "@web3-react/core"
import { FactoryService } from "../../services/FactoryService"
import { supportedChain } from "../../utils"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

// upBNB
import Common1 from '../../assets/images/nft/common1.webp'
import Common2 from '../../assets/images/nft/common2.webp'
import Rare1 from '../../assets/images/nft/rare1.webp'
import Rare2 from '../../assets/images/nft/rare2.webp'

// upCake
import Common1Cake from '../../assets/images/nft/common1cake.webp'
import Rare1Cake from '../../assets/images/nft/rare1cake.webp'
import Other1Cake from '../../assets/images/nft/other1cake.webp'

// upMatic
import Common1Matic from '../../assets/images/nft/common1matic.webp'
import Rare1Matic from '../../assets/images/nft/rare1matic.webp'

import { lighten, darken } from 'polished'
import StakeModal from "../../components/StakeModal"
import UnstakeModal from "../../components/UnstakeModal"
import { StakingService } from "../../services/StakingService"

// import BigNumber from 'bignumber.js'
// import { getBalanceNumber } from "../../utils/formatBalance"
import { formatEther } from '@ethersproject/units';

const PageWrapper = styled.div`
    padding-top: 1.5em;

 ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top:0em;
 `};
`

export const Option = styled.button<{ active: boolean }>`
  align-items: center;
  margin-right: 5px;
  font-size:1em;
  font-weight: ${({ active, theme }) => (active ? '600' : '500')};
  padding: 0.75em 0.75em 0.8em 0.75em;
  border-radius: 1em; 
  border: 0px;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? '': 'none')};
  background-color: ${({ active, theme }) => (active ? theme.primary1 :  'black')};
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text2)};
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }`

const NFTWrapper = styled.div`
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.15em;
    border-radius: 1em;
    grid-gap: 0.5em;
    background-color: ${({ theme }) => lighten(0.07, theme.bg1)};
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-columns: 1fr;
    `}

`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 52em;
    margin:auto;
 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;
 `};
`

const SmallCard = (props:any) => (
<Card 
    paddingTop='10px'
    textAlign='center'
    mb={2}
    mx={1}
    borderRadius={18}
    css={{ overflow: 'hidden', position: 'relative' }}
    {...props}
/>
)

const Name = (props:any) => (
    <Text
      as="h3"
      my={0}
      fontSize={2}
      fontFamily="sans-serif"
      textAlign="center"
      fontWeight="bold"
      {...props}
    />
  )
  
enum StakingStatus {
    None,
    Approving,
    Approved,
    Claiming,
    Claimed
 }
  // const ColumnLayout = (props:any) => (
  //   <Flex
  //     flexDirection="column"
  //     flexWrap="wrap"
  //     alignItems="center"
  //     mx="auto"
  //     css={{
  //       height: '650px',
  //       '& > div:nth-child(3)': {
  //         marginTop: '40px'
  //       }
  //     }}
  //     {...props}
  //   />
  // )
export const Stake = () => {
    const { account, library, chainId } = useWeb3React();
    const [modalStakeOpen, setStakeModalOpen] = useState<boolean>(false)
    const toggleStakeModal = () => { 
      setStakeModalOpen(!modalStakeOpen) }
    const [modalUnstakeOpen, setUnstakeModalOpen] = useState<boolean>(false)
    const toggleUnstakeModal = () => { 
        setUnstakeModalOpen(!modalUnstakeOpen) }
  
    const [ artId, setArtId ] = useState("");
    const [ status, setStatus ] = useState<StakingStatus>(StakingStatus.None);

    const [ commonUpmatic1ImageUrl, setCommonUpmatic1ImageUrl] = useState("");
    const [ commonUpmatic1Size, setCommonUpmatic1Size ] = useState(0);
    const [ rareUpmatic1ImageUrl, setRareUpmatic1ImageUrl] = useState("");
    const [ rareUpmatic1Size, setRareUpmatic1Size ] = useState(0);


    const [ otherUpcake1Size, setOtherUpcake1Size ] = useState(0);
    const [ otherUpcake1ImageUrl, setOtherUpcake1ImageUrl ] = useState("");

    const [ commonUpcake1Size, setCommonUpcake1Size ] = useState(0);
    const [ commonUpcake1ImageUrl, setCommonUpcake1ImageUrl ] = useState("");
    const [ rareUpcake1Size, setRareUpcake1Size ] = useState(0);
    const [ rareUpcake1ImageUrl, setRareUpcake1ImageUrl ] = useState("");

    const [ commonUpbnb1Size, setCommonUpbnb1Size ] = useState(0);
    const [ commonUpbnb2Size, setCommonUpbnb2Size ] = useState(0);

    const [ commonUpbnb1ImageUrl, setCommonUpbnb1ImageUrl ] = useState("");
    const [ commonUpbnb2ImageUrl, setCommonUpbnb2ImageUrl ] = useState("");

    const [ rareUpbnb1Size, setRareUpbnb1Size ] = useState(0);
    const [ rareUpbnb2Size, setRareUpbnb2Size ] = useState(0);

    const [ rareUpbnb1ImageUrl, setRareUpbnb1ImageUrl ] = useState("");
    const [ rareUpbnb2ImageUrl, setRareUpbnb2ImageUrl ] = useState("");

    const [ commonUpmaticStaked1Size, setCommonUpmaticStaked1Size ] = useState(0);
    const [ commonUpmaticReward1Size, setCommonUpmaticReward1Size ] = useState("");
    const [ rareUpmaticStaked1Size, setRareUpmaticStaked1Size ] = useState(0);
    const [ rareUpmaticReward1Size, setRareUpmaticReward1Size ] = useState("");

    // // Common Staked Size
    const [ otherUpcakeStaked1Size, setOtherUpcakeStaked1Size ] = useState(0);
    const [ commonUpbnbStaked1Size, setCommonUpbnbStaked1Size ] = useState(0);
    const [ commonUpbnbStaked2Size, setCommonUpbnbStaked2Size ] = useState(0);
    const [ commonUpcakeStaked1Size, setCommonUpcakeStaked1Size ] = useState(0);

    // // Common Reward Size
    const [ otherUpcakeReward1Size, setOtherUpcakeReward1Size ] = useState("");
    const [ commonUpbnbReward1Size, setCommonUpbnbReward1Size ] = useState("");
    const [ commonUpbnbReward2Size, setCommonUpbnbReward2Size ] = useState("");
    const [ commonUpcakeReward1Size, setCommonUpcakeReward1Size ] = useState("");
    
    
    // // Rare Staked Size
    const [ rareUpbnbStaked1Size, setRareUpbnbStaked1Size ] = useState(0);
    const [ rareUpbnbStaked2Size, setRareUpbnbStaked2Size ] = useState(0);
    const [ rareUpcakeStaked1Size, setRareUpcakeStaked1Size ] = useState(0);

    // // Rare Reward Size
    const [ rareUpbnbReward1Size, setRareUpbnbReward1Size ] = useState("");
    const [ rareUpbnbReward2Size, setRareUpbnbReward2Size ] = useState("");
    const [ rareUpcakeReward1Size, setRareUpcakeReward1Size ] = useState("");

    const stakeNft = (id:any) => {
      setArtId(id)
      toggleStakeModal()
    }
    
    const unstakeNft = (id:any) => {
      setArtId(id)
      toggleUnstakeModal()
    }

    const claimReward = async(id:any) => {
      setStatus(StakingStatus.Claiming);
      // setArtId(id);
      try{
        let stakingContract = new StakingService(library, account!, chainId!);
        let poolId = stakingContract.getPoolByArtId(id);
        const txResponse = await stakingContract.getReward(poolId.toString());
        if (txResponse) {
            const receipt = await txResponse.wait()
            if (receipt?.status === 1) {
                setStatus(StakingStatus.Claimed);
            }
            else {
                setStatus(StakingStatus.None);
            }
        }
      }catch(e){
        setStatus(StakingStatus.None);
      }
    }
    
    const NFTCard = (props:any) => (
        <SmallCard>
    
          <LazyLoadImage
            alt={"NFT"}
            effect="blur"
            src={props.image}
            width={150}
            height={200} >
         </LazyLoadImage>

          <Box py={3} px={3}>
            <Name>Owned : {props.owned}</Name>
            <Name>Staked : {props.staked}</Name>
            <Name>Pending Reward : {props.reward}</Name>
            <Name>{props.title}</Name>
    
            <br/>
    
            <Option active={props.owned > 0} onClick={() => stakeNft(props.id)}>
                Stake
            </Option>

            <Option active={props.staked > 0} onClick={() => unstakeNft(props.id)}>
                Unstake
            </Option>

            <Option active={props.reward > 0 && status == StakingStatus.None} onClick={() => claimReward(props.id)}>
                {status == StakingStatus.None || status == StakingStatus.Claimed ? `Claim Reward` : `Claiming...`}
            </Option>
          </Box>
        </SmallCard>
    )
    
    let stakingContract: any = null;
    useEffect(() => {
        if(account && supportedChain(chainId)) {
            const getBalance = async () => {
                const contract = new FactoryService(library, account, chainId!);
                stakingContract = new StakingService(library, account, chainId!);
                // BSC
                if(chainId == 56){
                  // upbnb
                  const common1 = await contract.balanceOf("56042"); //upBNB MGE (CZ)
                  const common2 = await contract.balanceOf("170"); //Flying Carpet 
                  setCommonUpbnb1Size(common1.toNumber());
                  setCommonUpbnb2Size(common2.toNumber());

                  const rare1 = await contract.balanceOf("56069"); //upBNB MGE (CZ)
                  const rare2 = await contract.balanceOf("70"); //Flying Carpet
                  setRareUpbnb1Size(rare1.toNumber());
                  setRareUpbnb2Size(rare2.toNumber());

                  const commonStaked1 = await stakingContract.getUserInfoByArtId("56042");
                  const commonRewardStaked1 = await stakingContract.getPendingReward("56042");
                  setCommonUpbnbStaked1Size(commonStaked1[0].toNumber());
                  setCommonUpbnbReward1Size(parseFloat(formatEther(commonRewardStaked1.toString())).toFixed(4));
                  const commonStaked2 = await stakingContract.getUserInfoByArtId("170");
                  const commonRewardStaked2 = await stakingContract.getPendingReward("170");
                  setCommonUpbnbStaked2Size(commonStaked2[0].toNumber());
                  setCommonUpbnbReward2Size(parseFloat(formatEther(commonRewardStaked2.toString())).toFixed(4));
                  const rareStaked1 = await stakingContract.getUserInfoByArtId("56069");
                  const rareRewardStaked1 = await stakingContract.getPendingReward("56069");
                  setRareUpbnbStaked1Size(rareStaked1[0].toNumber());
                  setRareUpbnbReward1Size(parseFloat(formatEther(rareRewardStaked1.toString())).toFixed(4));
                  const rareStaked2 = await stakingContract.getUserInfoByArtId("70");
                  const rareRewardStaked2 = await stakingContract.getPendingReward("70");
                  setRareUpbnbStaked2Size(rareStaked2[0].toNumber());
                  setRareUpbnbReward2Size(parseFloat(formatEther(rareRewardStaked2.toString())).toFixed(4));

                  // upbnb

                  // upcake
                  const commonUpcake1 = await contract.balanceOf("56142"); //upBNB MGE (CZ)
                  setCommonUpcake1Size(commonUpcake1.toNumber());

                  const rareUpcake1 = await contract.balanceOf("56169"); //upBNB MGE (CZ)
                  setRareUpcake1Size(rareUpcake1.toNumber());

                  const otherUpcake1 = await contract.balanceOf("56269"); //upBNB MGE (CZ)
                  setOtherUpcake1Size(otherUpcake1.toNumber());

                  const otherUpcakeStaked1 = await stakingContract.getUserInfoByArtId("56269");
                  const otherRewardStaked1 = await stakingContract.getPendingReward("56269");
                  setOtherUpcakeStaked1Size(otherUpcakeStaked1[0].toNumber());
                  setOtherUpcakeReward1Size(parseFloat(formatEther(otherRewardStaked1.toString())).toFixed(4));
                  const commonUpcakeStaked1 = await stakingContract.getUserInfoByArtId("56142");
                  const commonUpcakeRewardStaked1 = await stakingContract.getPendingReward("56142");
                  setCommonUpcakeStaked1Size(commonUpcakeStaked1[0].toNumber());
                  setCommonUpcakeReward1Size(parseFloat(formatEther(commonUpcakeRewardStaked1.toString())).toFixed(4));
                  const rareUpcakeStaked1 = await stakingContract.getUserInfoByArtId("56169");
                  const rareUpcakeRewardStaked1 = await stakingContract.getPendingReward("56169");
                  setRareUpcakeStaked1Size(rareUpcakeStaked1[0].toNumber());
                  setRareUpcakeReward1Size(parseFloat(formatEther(rareUpcakeRewardStaked1.toString())).toFixed(4));
                  // upcake
                }
                else if(chainId == 137){
                  const common1 = await contract.balanceOf("169"); //upMatic Flying Carpet
                  setCommonUpmatic1Size(common1.toNumber());
                  const rare1 = await contract.balanceOf("69"); //upMatic Flying Carpet
                  setRareUpmatic1Size(rare1.toNumber());

                  const commonStaked1 = await stakingContract.getUserInfoByArtId("169");
                  const commonRewardStaked1 = await stakingContract.getPendingReward("169");
                  setCommonUpmaticStaked1Size(commonStaked1[0].toNumber());
                  setCommonUpmaticReward1Size(parseFloat(formatEther(commonRewardStaked1.toString())).toFixed(4));

                  const rareStaked1 = await stakingContract.getUserInfoByArtId("69");
                  const rareRewardStaked1 = await stakingContract.getPendingReward("69");
                  setRareUpmaticStaked1Size(rareStaked1[0].toNumber());
                  setRareUpmaticReward1Size(parseFloat(formatEther(rareRewardStaked1.toString())).toFixed(4));

                }
                // else if(chainId == 1){
                //   // const common1 = await contract.balanceOf("0");
                //   const common2 = await contract.balanceOf("169");

                //   setCommon1Size(0);
                //   setCommon2Size(common2.toNumber());

                //   setRare1Size(0);
                //   setRare2Size(0);
                // }
            };
            getBalance();


            if(chainId == 56){
              setCommonUpbnb1ImageUrl(Common1);
              setCommonUpbnb2ImageUrl(Common2);
              setRareUpbnb1ImageUrl(Rare1);
              setRareUpbnb2ImageUrl(Rare2);

              setCommonUpcake1ImageUrl(Common1Cake);
              setRareUpcake1ImageUrl(Rare1Cake);
              setOtherUpcake1ImageUrl(Other1Cake);
            }            
            else if(chainId == 137){
              setCommonUpmatic1ImageUrl(Common1Matic);
              setRareUpmatic1ImageUrl(Rare1Matic);
            }
            // else if(chainId == 1){
            //   setCommon1ImageUrl(Common1);
            //   setCommon2ImageUrl(Common2);
            //   setRare1ImageUrl(Rare1);
            //   setRare2ImageUrl(Rare2);
            // }
        }
    },  [library, account, chainId]);

    return (
        <PageWrapper>
            <PrimaryCard width="auto" margin="auto">
                <CardContent>
                  <Title>
                    Staking
                  </Title>

                  {(() => {
                    if (account){
                        // BSC 
                        if(chainId == 56){
                          return (
                              <NFTWrapper>
                                  <NFTCard title={"CZ Pixel Art (Common)"} image={commonUpbnb1ImageUrl} owned={commonUpbnb1Size} id={"56042"} staked={commonUpbnbStaked1Size} reward={commonUpbnbReward1Size}>
                                  </NFTCard>

                                  <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpbnb2ImageUrl} owned={commonUpbnb2Size} id={"170"} staked={commonUpbnbStaked2Size} reward={commonUpbnbReward2Size}>
                                  </NFTCard>
                                    
                                  <NFTCard title={"CZ Pixel Art (Rare)"} image={rareUpbnb1ImageUrl} owned={rareUpbnb1Size} id={"56069"} staked={rareUpbnbStaked1Size} reward={rareUpbnbReward1Size}>                                        
                                  </NFTCard>

                                  <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpbnb2ImageUrl} owned={rareUpbnb2Size} id={"70"} staked={rareUpbnbStaked2Size} reward={rareUpbnbReward2Size}>
                                  </NFTCard>

                                  <NFTCard title={"Cake Pixel Art (Common)"} image={commonUpcake1ImageUrl} owned={commonUpcake1Size} id={"56142"} staked={commonUpcakeStaked1Size} reward={commonUpcakeReward1Size}>
                                  </NFTCard>

                                  <NFTCard title={"Cake Pixel Art (Rare)"} image={rareUpcake1ImageUrl} owned={rareUpcake1Size} id={"56169"} staked={rareUpcakeStaked1Size} reward={rareUpcakeReward1Size}>                                        
                                  </NFTCard>

                                  <NFTCard title={"Space Art"} image={otherUpcake1ImageUrl} owned={otherUpcake1Size} id={"56269"} staked={otherUpcakeStaked1Size} reward={otherUpcakeReward1Size}>                                        
                                  </NFTCard>
                              </NFTWrapper>
                          )
                        }
                        // MATIC
                        else if(chainId == 137){
                          return (
                              <NFTWrapper>
                                  <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpmatic1ImageUrl} owned={commonUpmatic1Size} id={"169"} staked={commonUpmaticStaked1Size} reward={commonUpmaticReward1Size}>
                                  </NFTCard>

                                  <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpmatic1ImageUrl} owned={rareUpmatic1Size} id={"69"} staked={rareUpmaticStaked1Size} reward={rareUpmaticReward1Size}>
                                  </NFTCard>
                              </NFTWrapper>
                          )
                        }
                        else if(chainId == 1){
                          return (
                            <div>
                              {/* <div>
                                <label>Common Art</label>
                                <NFTWrapper>
                                        <NFTCard title={"Flying Carpet Art"} image={common2ImageUrl} owned={common2Size}>
                                        </NFTCard>
                                  </NFTWrapper>
                              </div> */}
                            </div>
                          )
                        }
                        else{
                          return <Title>Chain not supported</Title>
                        }
                    } else { 
                        return (<Text>{'Connect to a wallet'}</Text>)
                    }
                    
                    return null;
                  })()}
                </CardContent>
            </PrimaryCard>

            <StakeModal
              modalOpen={modalStakeOpen}
              toggleInfoModal={toggleStakeModal}
              artId={artId}
            />

            <UnstakeModal
              modalOpen={modalUnstakeOpen}
              toggleInfoModal={toggleUnstakeModal}
              artId={artId}
            />
        </PageWrapper>
    )
}
