import { Contract } from '@ethersproject/contracts'
// import forgeAbi from '../constants/abis/forge.json'
import forgeBscAbi from '../constants/abis/forgeBsc.json'
import forgeMaticAbi from '../constants/abis/forgeMaticAbi.json'
import { Web3Provider } from '@ethersproject/providers'
import { 
    FORGE_ART1_BSC_ADDRESS, FORGE_ART2_BSC_ADDRESS, FORGE_ART1_BSC_CAKE_ADDRESS, FORGE_ART2_BSC_CAKE_ADDRESS,
    FORGE_ART1_MATIC_ADDRESS
} from '../constants'
// import { GasStationService } from './GasStationService';

export class ForgeService {
    private contractArt1: Contract;
    private contractArt2: Contract;
    private contractArt3: Contract;
    private contractArt4: Contract;
    // private gasStation: GasStationService;

    private signer: any;
    private chainId: number;

    constructor(library: Web3Provider, account: string, chainId: number) {
        this.signer = library.getSigner(account).connectUnchecked()
        this.chainId = chainId;
        this.contractArt1 = new Contract(FORGE_ART1_BSC_ADDRESS, forgeBscAbi, this.signer);
        this.contractArt2 = new Contract(FORGE_ART2_BSC_ADDRESS, forgeBscAbi, this.signer);
        this.contractArt3 = new Contract(FORGE_ART1_BSC_ADDRESS, forgeBscAbi, this.signer);
        this.contractArt4 = new Contract(FORGE_ART2_BSC_ADDRESS, forgeBscAbi, this.signer);
    
        if(chainId == 56) {
            this.contractArt1 = new Contract(FORGE_ART1_BSC_ADDRESS, forgeBscAbi, this.signer);
            this.contractArt2 = new Contract(FORGE_ART2_BSC_ADDRESS, forgeBscAbi, this.signer);
            this.contractArt3 = new Contract(FORGE_ART1_BSC_CAKE_ADDRESS, forgeBscAbi, this.signer);
            this.contractArt4 = new Contract(FORGE_ART2_BSC_CAKE_ADDRESS, forgeBscAbi, this.signer);
        }
        else if(chainId == 137){
            this.contractArt1 = new Contract(FORGE_ART1_MATIC_ADDRESS, forgeMaticAbi, this.signer);
        }
        
        // this.gasStation = new GasStationService(chainId);
    }

    // write
    public async upgrade(artId: string) {
        // let estimation = await this.gasStation.getGas();
        // let options = { gasLimit: 100000, gasPrice: estimation };
        // return await this.contract.UpgradeNFT();
        let contract = this.getContractArtByArtId(artId);
        if(contract != null){
            return await contract.UpgradeNFT();
        }
        return new Promise((resolve,reject) => {
            resolve(0);
        });
    }
    // end write

    public getContractArtByArtId(artId: string){
        if(this.chainId == 56){
            if(artId == "56042"){
                return this.contractArt1;
            }
            else if(artId == "170"){
                return this.contractArt2;
            }
            else if(artId == "56142"){
                return this.contractArt3;
            }
            else if(artId == "56242"){
                return this.contractArt4;
            }
        }
        else if(this.chainId == 137){
            if(artId == "169"){
                return this.contractArt1;
            }
        }
        return null;
    }

    // read
    public async getUpgradeCost(artId: string) {
        let contract = this.getContractArtByArtId(artId);
        if(contract != null){
            return await contract.upgradeCost();
        }
        return new Promise((resolve,reject) => {
            resolve(0);
        });
    }
    // end read
}