import React from 'react';
import styled from 'styled-components';
import { TransactionResponse } from "@ethersproject/providers";
import Web3ReactManager from './components/Web3ReactManager';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { useToast } from './components/ToastProvider';
import { IconType } from './components/Icon/IconType';
import TransactionContext from './contexts/TransactionContext';
import Header from './components/Header';
import { Upgrade } from './pages/Upgrade';
import { MyNFT } from './pages/MyNFT';
// import { LandingPage } from './pages/LandingPage';
import Navigation from './components/Navigation';
import { Stake } from './pages/Stake';
import { Footer } from './components/Footer';
import { Sharft } from './pages/Sharft';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  min-height: calc(100vh - 11.5em);

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-height: calc(100vh - 20em);
  `};
`
export const App = () => {
  const { addToast } = useToast();
  const addPendingTransaction = (description: string, txResponse: TransactionResponse) => {
    txResponse.wait().then(txReceipt => {
      addToast(
        txReceipt.status === 1 ? IconType.Success : IconType.Failure,
        `${description} ${txReceipt.status === 1 ? "Completed" : "Failed"}`,
        txReceipt.transactionHash)
    })
  }  
 
  return (
      <HashRouter>
        <AppWrapper>
          <TransactionContext.Provider value={{ addPendingTransaction }}>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper>
              <Navigation>            
                <Web3ReactManager>           
                  <Switch> 
                    <Route exact strict path="/" component={MyNFT} />
                    <Route exact strict path="/stake" component={Stake} />
                    <Route exact strict path="/sharft" component={Sharft} />
                    <Route exact strict path="/upgrade" component={Upgrade} />
                    <Route exact strict path="/mynft" component={MyNFT} />
                  </Switch>             
                </Web3ReactManager>
              </Navigation> 
            </BodyWrapper>
            <Footer />
          </TransactionContext.Provider>
        </AppWrapper>
      </HashRouter>
  );
}

export default App;
