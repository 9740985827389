import { Contract } from '@ethersproject/contracts'
import stakingAbi from '../constants/abis/staking.json'
import stakingBscAbi from '../constants/abis/stakingBsc.json'
import { Web3Provider } from '@ethersproject/providers'
import { STAKING_MATIC_ADDRESS, STAKING_BSC_ADDRESS } from '../constants'
// import { GasStationService } from './GasStationService'

export class StakingService {
    private contract: Contract;
    // private gasStation: GasStationService;
    private address: string;
    private chainId: number;

    constructor(library: Web3Provider, account: string, chainId: number) {
        const signer = library.getSigner(account).connectUnchecked()
        this.chainId = chainId;
        this.contract = new Contract(STAKING_BSC_ADDRESS, stakingAbi, signer);
        if(chainId == 56) {
            this.contract = new Contract(STAKING_BSC_ADDRESS, stakingBscAbi, signer);
        }
        else if(chainId == 137) {
            this.contract = new Contract(STAKING_MATIC_ADDRESS, stakingBscAbi, signer);
        }
        this.address = account;
        // this.gasStation = new GasStationService(chainId);
    }

    // write
    public async stake(amount: string, artId: string) {
        // let estimation = await this.gasStation.getGas();
        // let options = { gasLimit: 100000, gasPrice: estimation };
        return await this.contract.deposit(this.getPoolByArtId(artId), amount);
    }

    public async unstake(amount: string, artId: string) {
        // let estimation = await this.gasStation.getGas();
        // let options = { gasLimit: 100000, gasPrice: estimation };
        return await this.contract.withdraw(this.getPoolByArtId(artId), amount);
    }
    // end write

    // read
    public getPoolByArtId(artId: string){
        let poolId = 0;
        if(this.chainId == 56){
            if(artId == "170"){
                poolId = 0;
            }
            else if(artId == "70"){
                poolId = 1;
            }
            else if(artId == "56042"){
                poolId = 2;
            }
            else if(artId == "56069"){
                poolId = 3;
            }
            else if(artId == "56142"){
                poolId = 4;
            }
            else if(artId == "56169"){
                poolId = 5;
            }
            else if(artId == "56269"){
                poolId = 6;
            }
        }
        else if(this.chainId == 137){
            if(artId == "69"){
                poolId = 0;
            }
            else if(artId == "169"){
                poolId = 1;
            }
        }
        return poolId;
    }

    public async getPendingReward(artId: string){
        return await this.contract.pendingReward(this.getPoolByArtId(artId), this.address);
    }

    public async getUserInfoByArtId(artId: string){
        return await this.contract.userInfo(this.getPoolByArtId(artId), this.address);
    }

    public async getPools(artId: string) {
        return await this.contract.poolInfo(artId);
    }

    public async getPoolInfoCount() {
        return await this.contract.poolInfoCount();
    }

    public async getUserInfo(poolId: string){
        return await this.contract.userInfo(poolId, this.address);
    }

    public async getReward(poolId: string){
        return await this.contract.withdraw(poolId, 0);
    }

    // end read
}