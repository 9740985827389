import { SHARFT_COMMON1_CAKE_ADDRESS } from './../constants/index';
import { Contract } from '@ethersproject/contracts'
import factoryAbi from '../constants/abis/factory.json'
import factoryBscAbi from '../constants/abis/factoryBsc.json'
import factoryEthAbi from '../constants/abis/factoryEth.json'
import { Web3Provider } from '@ethersproject/providers'
import { 
    FACTORY_MATIC_ADDRESS, FACTORY_BSC_ADDRESS, FACTORY_ETH_ADDRESS, 
    STAKING_BSC_ADDRESS, STAKING_MATIC_ADDRESS, 
    AIRDROP_ADDRESS_ALL, 
    FORGE_ART1_BSC_ADDRESS, FORGE_ART2_BSC_ADDRESS, 
    SHARFT_COMMON1_ADDRESS, SHARFT_COMMON2_ADDRESS, SHARFT_RARE1_ADDRESS, SHARFT_RARE2_ADDRESS, FORGE_ART1_MATIC_ADDRESS } from '../constants'
import { GasStationService } from './GasStationService';

export class FactoryService {
    private contract: Contract;
    private address: string;
    private gasStation: GasStationService;
    private chainId: number;

    constructor(library: Web3Provider, account: string, chainId: number) {
        const signer = library.getSigner(account).connectUnchecked()
        this.chainId = chainId;
        this.contract = new Contract(FACTORY_BSC_ADDRESS, factoryAbi, signer);
        if(chainId == 56) {
            this.contract = new Contract(FACTORY_BSC_ADDRESS, factoryBscAbi, signer);
        }        
        else if(chainId == 1){
            this.contract = new Contract(FACTORY_ETH_ADDRESS, factoryEthAbi, signer);
        }
        else if(chainId == 137){
            this.contract = new Contract(FACTORY_MATIC_ADDRESS, factoryEthAbi, signer);
        }
        this.address = account;
        this.gasStation = new GasStationService(chainId);
    }

    // Read
    public async balanceOf(artId: string){
        return await this.contract.balanceOf(this.address, artId);
    }

    public async isApprovedForAllClaim(){
        return await this.contract.isApprovedForAll(this.address, AIRDROP_ADDRESS_ALL);
    }

    public async isApprovedForAllStake(){
        if(this.chainId == 56){
            return await this.contract.isApprovedForAll(this.address, STAKING_BSC_ADDRESS);
        }
        else if(this.chainId == 137){
            return await this.contract.isApprovedForAll(this.address, STAKING_MATIC_ADDRESS);
        }
    }

    public getContractArtByArtId(artId: string){
        if(this.chainId == 56){
            if(artId == "56042"){
                return FORGE_ART1_BSC_ADDRESS;
            }
            else if(artId == "170"){
                return FORGE_ART2_BSC_ADDRESS;
            }
        }
        else if(this.chainId == 137){
            if(artId == "169"){
                return FORGE_ART1_MATIC_ADDRESS;
            }
        }
        return null;
    }

    public async isApprovedForAllForge(artId: string){
        return await this.contract.isApprovedForAll(this.address, this.getContractArtByArtId(artId));
    }
    // End Read

    // Write
    public async approveClaim(){
        let estimation = await this.gasStation.getGas();
        let options = { gasLimit: 100000, gasPrice: estimation };
        return await this.contract.setApprovalForAll(AIRDROP_ADDRESS_ALL, true, options);
    }

    public async approveStake(){
        // let estimation = await this.gasStation.getGas();
        // let options = { gasLimit: 100000, gasPrice: estimation };
        if(this.chainId == 56){
            return await this.contract.setApprovalForAll(STAKING_BSC_ADDRESS, true);
        }
    }

    public async approveForge(artId: string){
        // let estimation = await this.gasStation.getGas();
        // let options = { gasLimit: 100000, gasPrice: estimation };
        if(this.chainId == 56){
            if(artId == "56042"){
                return await this.contract.setApprovalForAll(FORGE_ART1_BSC_ADDRESS, true);
            }
            else if(artId == "170"){
                return await this.contract.setApprovalForAll(FORGE_ART2_BSC_ADDRESS, true);
            }
        }
        return 0;
    }

    public async approveSharft(artId: string){
        if(this.chainId == 56){
            if(artId == "56042"){
                return await this.contract.setApprovalForAll(SHARFT_COMMON1_ADDRESS, true);
            }
            else if(artId == "170"){
                return await this.contract.setApprovalForAll(SHARFT_COMMON2_ADDRESS, true);
            }
            else if(artId == "56069"){
                return await this.contract.setApprovalForAll(SHARFT_RARE1_ADDRESS, true);
            }
            else if(artId == "70"){
                return await this.contract.setApprovalForAll(SHARFT_RARE2_ADDRESS, true);
            }
            else if(artId == "56142"){
                return await this.contract.setApprovalForAll(SHARFT_COMMON1_CAKE_ADDRESS, true);
            }
        }
        return 0;
    }


    public async isApprovedForAllSharft(artId: string){
        if(this.chainId == 56){
            if(artId == "56042"){
                return await this.contract.isApprovedForAll(this.address, SHARFT_COMMON1_ADDRESS);
            }
            else if(artId == "170"){
                return await this.contract.isApprovedForAll(this.address, SHARFT_COMMON2_ADDRESS);
            }
            else if(artId == "56069"){
                return await this.contract.isApprovedForAll(this.address, SHARFT_RARE1_ADDRESS);
            }
            else if(artId == "70"){
                return await this.contract.isApprovedForAll(this.address, SHARFT_RARE2_ADDRESS);
            }
            else if(artId == "56142"){
                return await this.contract.isApprovedForAll(this.address, SHARFT_COMMON1_CAKE_ADDRESS);
            }
        }
        return 0;
    }
    // End Write
}