import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, walletconnect } from '../connectors'

export const NETWORK_LABELS: { [chainId in number]?: string } = {
  1: 'Ethereum',  
  3: 'Ropsten',
  4: 'Rinkeby',
  5: 'Görli',
  42: 'Kovan',
  56: 'Binance',
  137: 'Matic'
}

export const AIRDROP_ADDRESS_ALL = '0xbbbbbbc5518b0448130c6befb35ac991b48b0e75'

// ETH
export const FACTORY_ETH_ADDRESS = '0xAce3134DDe462a547a2b4f5B8dbd5B0D7f79d25f';
export const AIRDROP_ETH_ADDRESS = '0xbbbbbbc5518b0448130c6befb35ac991b48b0e75'

// Matic
export const FACTORY_MATIC_ADDRESS = '0x3B0CA28F98951e359293fB50f83F1b0a4642350F'
export const STAKING_MATIC_ADDRESS = '0x68CEd9a732615d2c375D6AFD385f48f2088B13fF'
export const FORGE_MATIC_ADDRESS = '0x4E6768340D4D8657495EaE74f0a3A053d40BEe3C'
export const AIRDROP_MATIC_ADDRESS = '0x46E378e9812A568780066611782bc90bF0247bE4'

export const AIRDROP_ADDRESS = '0xbbbbbbc5518b0448130c6befb35ac991b48b0e75'
export const AIRDROP_ADDRESS_NEW = '0x46E378e9812A568780066611782bc90bF0247bE4' // OLD

// BSC
export const STAKING_BSC_ADDRESS = '0x9B64414532837CE2A72c19e4F7DfDaB8d234BEC2'
export const FACTORY_BSC_ADDRESS = '0x7Ac8bF2a85F3f6a108c99C783c2229Abd75cC9cf'
export const AIRDROP_BSC_ADDRESS_NEW = '0xbbbbbbc5518b0448130c6befb35ac991b48b0e75'
export const AIRDROP_BSC_ADDRESS_CAKE_COMMON = '0xc1dF194375068c9565E7656D9490142660D08fd5'
export const AIRDROP_BSC_ADDRESS_CAKE_SPACE = '0xEC90eeE99f234Ab7C0849dC88EE6b86060117177'

export const AIRDROP_BSC_ADDRESS = '0x7B515c399b8fAD9eD1c0843cEF0F36C95652017A' // OLD

export const FORGE_ART1_BSC_ADDRESS = '0x9b15a35a76b823F387f39Bc0A1150725fbb27332'
export const FORGE_ART2_BSC_ADDRESS = '0x48a252b53F9A072A5a4Dc4Ef21cB4B55ADed475f'
export const FORGE_ART1_BSC_CAKE_ADDRESS = '0x685Ab79120A344576C0576BeaB14C79b0E363F9D'
export const FORGE_ART2_BSC_CAKE_ADDRESS = '0x685Ab79120A344576C0576BeaB14C79b0E363F9D'
export const SHARFT_COMMON1_ADDRESS = '0xf7E8786EE810bD96eD7BF74F0d5E671691C343Cc'
export const SHARFT_COMMON2_ADDRESS = '0x8AB5DBC7A52ba9695e9e84f858E3aa8964b11E5c'
export const SHARFT_COMMON1_CAKE_ADDRESS = '0x5F1c47448002A673ea7c40b5D8C9a9b28EF25b7b'
export const SHARFT_RARE1_ADDRESS = '0x74D6d2A5F4e22e6dbb1A781F8Ff38AbE12e90DA9'
export const SHARFT_RARE2_ADDRESS = '0x8F7262bD292768D5F8b918EEf50A34244629080b'

export const FORGE_ART1_MATIC_ADDRESS = '0x4E6768340D4D8657495EaE74f0a3A053d40BEe3C'

export const SHARFT_MATIC_COMMON1_ADDRESS = '0xf7E8786EE810bD96eD7BF74F0d5E671691C343Cc'
export const SHARFT_MATIC_RARE1_ADDRESS = '0x74D6d2A5F4e22e6dbb1A781F8Ff38AbE12e90DA9'

export interface WalletInfo {
    connector?: AbstractConnector
    name: string
    iconName: string
    description: string
    href: string | null
    color: string
    primary?: true
    mobile?: true
    mobileOnly?: true
  }

  export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    INJECTED: {
      connector: injected,
      name: 'Injected',
      iconName: 'arrow-right.svg',
      description: 'Injected web3 provider.',
      href: null,
      color: '#010101',
      primary: true
    },
    METAMASK: {
      connector: injected,
      name: 'MetaMask',
      iconName: 'metamask.png',
      description: 'Easy-to-use browser extension.',
      href: null,
      color: '#E8831D'
    },
    WALLET_CONNECT: {
      connector: walletconnect,
      name: 'WalletConnect',
      iconName: 'walletConnectIcon.svg',
      description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
      href: null,
      color: '#4196FC',
      mobile: true
    }
  }

export const NetworkContextName = 'NETWORK'  
