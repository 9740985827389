import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { Box, Card, Text } from 'rebass/styled-components'
import { useWeb3React } from "@web3-react/core"
import { FactoryService } from "../../services/FactoryService"
import { supportedChain } from "../../utils"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import Common1 from '../../assets/images/nft/common1.webp'
import Common2 from '../../assets/images/nft/common2.webp'
import Rare1 from '../../assets/images/nft/rare1.webp'
import Rare2 from '../../assets/images/nft/rare2.webp'

// upCake
import Common1Cake from '../../assets/images/nft/common1cake.webp'
import Rare1Cake from '../../assets/images/nft/rare1cake.webp'
import Other1Cake from '../../assets/images/nft/other1cake.webp'

// upMatic
import Common1Matic from '../../assets/images/nft/common1matic.webp'
import Rare1Matic from '../../assets/images/nft/rare1matic.webp'

import { lighten, darken } from 'polished'
import { SharftService } from "../../services/SharftService"
import SharftModal from "../../components/SharftModal"
import UnsharftModal from "../../components/UnsharftModal"
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from "../../utils/formatBalance"

const PageWrapper = styled.div`
    padding-top: 1.5em;

 ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top:0em;
 `};
`

export const Option = styled.button<{ active: boolean }>`
  align-items: center;
  margin-right: 5px;
  font-size:1em;
  font-weight: ${({ active, theme }) => (active ? '600' : '500')};
  padding: 0.75em 0.75em 0.8em 0.75em;
  border-radius: 1em; 
  border: 0px;
  outline: none;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? '': 'none')};
  background-color: ${({ active, theme }) => (active ? theme.primary1 :  'black')};
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text2)};
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }`

const NFTWrapper = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.15em;
  border-radius: 1em;
  grid-gap: 0.5em;
  background-color: ${({ theme }) => lighten(0.07, theme.bg1)};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  grid-template-columns: 1fr;
  `}
`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 52em;
    margin: auto;
 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;
 `};
`

const SmallCard = (props:any) => (
<Card 
    paddingTop='10px'
    textAlign='center'
    mb={2}
    mx={1}
    borderRadius={18}
    css={{ overflow: 'hidden', position: 'relative' }}
    {...props}
/>
)

const Name = (props:any) => (
    <Text
      as="h3"
      my={0}
      fontSize={2}
      fontFamily="sans-serif"
      textAlign="center"
      fontWeight="bold"
      {...props}
    />
  )
  

  // const ColumnLayout = (props:any) => (
  //   <Flex
  //     flexDirection="column"
  //     flexWrap="wrap"
  //     alignItems="center"
  //     mx="auto"
  //     css={{
  //       height: '650px',
  //       '& > div:nth-child(3)': {
  //         marginTop: '40px'
  //       }
  //     }}
  //     {...props}
  //   />
  // )
export const Sharft = () => {
    const { account, library, chainId } = useWeb3React();
    const [modalSharftOpen, setSharftModalOpen] = useState<boolean>(false)
    const toggleSharftModal = () => { 
      setSharftModalOpen(!modalSharftOpen) }
    const [modalUnsharftOpen, setUnsharftModalOpen] = useState<boolean>(false)
    const toggleUnsharftModal = () => { 
        setUnsharftModalOpen(!modalUnsharftOpen) }
  
    const [ artId, setArtId ] = useState("");
  
    const [ commonUpmatic1ImageUrl, setCommonUpmatic1ImageUrl] = useState("");
    const [ commonUpmatic1Size, setCommonUpmatic1Size ] = useState(0);
    const [ rareUpmatic1ImageUrl, setRareUpmatic1ImageUrl] = useState("");
    const [ rareUpmatic1Size, setRareUpmatic1Size ] = useState(0);

    const [ commonUpmatic1SharftTokenSize, setCommonUpmatic1SharftTokenSize ] = useState(0);
    const [ rareUpmatic1SharftTokenSize, setRareUpmatic1SharftTokenSize ] = useState(0);
    
    const [ otherUpcake1SharftTokenSize, setOtherUpcake1SharftTokenSize ] = useState(0);
    const [ commonUpbnb1SharftTokenSize, setCommonUpbnb1SharftTokenSize ] = useState(0);
    const [ commonUpbnb2SharftTokenSize, setCommonUpbnb2SharftTokenSize ] = useState(0);
    const [ rareUpbnb1SharftTokenSize, setRareUpbnb1SharftTokenSize ] = useState(0);
    const [ rareUpbnb2SharftTokenSize, setRareUpbnb2SharftTokenSize ] = useState(0);
    const [ commonUpcake1SharftTokenSize, setCommonUpcake1SharftTokenSize ] = useState(0);
    const [ rareUpcake1SharftTokenSize, setRareUpcake1SharftTokenSize ] = useState(0);

    const [ otherUpcake1Size, setOtherUpcake1Size ] = useState(0);
    const [ otherUpcake1ImageUrl, setOtherUpcake1ImageUrl ] = useState("");

    const [ commonUpcake1Size, setCommonUpcake1Size ] = useState(0);
    const [ commonUpcake1ImageUrl, setCommonUpcake1ImageUrl ] = useState("");
    const [ rareUpcake1Size, setRareUpcake1Size ] = useState(0);
    const [ rareUpcake1ImageUrl, setRareUpcake1ImageUrl ] = useState("");

    const [ commonUpbnb1Size, setCommonUpbnb1Size ] = useState(0);
    const [ commonUpbnb2Size, setCommonUpbnb2Size ] = useState(0);

    const [ commonUpbnb1ImageUrl, setCommonUpbnb1ImageUrl ] = useState("");
    const [ commonUpbnb2ImageUrl, setCommonUpbnb2ImageUrl ] = useState("");

    const [ rareUpbnb1Size, setRareUpbnb1Size ] = useState(0);
    const [ rareUpbnb2Size, setRareUpbnb2Size ] = useState(0);

    const [ rareUpbnb1ImageUrl, setRareUpbnb1ImageUrl ] = useState("");
    const [ rareUpbnb2ImageUrl, setRareUpbnb2ImageUrl ] = useState("");

    const sharftNft = (id:any) => {
      setArtId(id)
      toggleSharftModal()
    }
    
    const unsharftNft = (id:any) => {
      setArtId(id)
      toggleUnsharftModal()
    }
    
    const trade = (id:any, site:string) => {
      // CZ Common
      if(id == "56042"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&tokenOut=0xf7E8786EE810bD96eD7BF74F0d5E671691C343Cc", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0xf7E8786EE810bD96eD7BF74F0d5E671691C343Cc", "_blank");
        }
      }
      // CZ Rare
      else  if(id == "56069"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0x1759254eb142bcf0175347d5a0f3c19235538a9a&tokenOut=0x74D6d2A5F4e22e6dbb1A781F8Ff38AbE12e90DA9", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0x74D6d2A5F4e22e6dbb1A781F8Ff38AbE12e90DA9", "_blank");
        }
      }
      // Flying carpet (common)
      else if(id == "170"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&tokenOut=0x8AB5DBC7A52ba9695e9e84f858E3aa8964b11E5c", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0x8AB5DBC7A52ba9695e9e84f858E3aa8964b11E5c", "_blank");
        }
      }
      // Flying carpet (rare)
      else if(id == "70"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0x1759254eb142bcf0175347d5a0f3c19235538a9a&tokenOut=0x8F7262bD292768D5F8b918EEf50A34244629080b", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0x8F7262bD292768D5F8b918EEf50A34244629080b", "_blank");
        }
      }

      // Cake common
      else if(id == "56142"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&tokenOut=0x5F1c47448002A673ea7c40b5D8C9a9b28EF25b7b", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0x5F1c47448002A673ea7c40b5D8C9a9b28EF25b7b", "_blank");
        }
      }
      // Cake Rare
      else if(id == "56169"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&tokenOut=0x75b6A08a57A16d63e909D892C4025cF167EdB038", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0x75b6A08a57A16d63e909D892C4025cF167EdB038", "_blank");
        }
      }
      // Cake Space 
      else if(id == "56269"){
        if(site == "bogSwap"){
          window.open("https://app.bogged.finance/swap?tokenIn=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&tokenOut=0x8424086E9d4349d8e67367494CBfE45cE338F24D", "_blank"); //to open new page
        }
        else if(site == "pooCoin"){
          window.open("https://poocoin.app/tokens/0x8424086E9d4349d8e67367494CBfE45cE338F24D", "_blank");
        }
      }
    }

    const NFTCard = (props:any) => (
        <SmallCard>
    
          <LazyLoadImage
            alt={"NFT"}
            effect="blur"
            src={props.image}
            width={150}
            height={200} >
             {/* <Timeline 
               target={
                 <img src={props.image} />
                }>
              <Tween 
                staggerFrom={{ opacity: 0, y: '-20px' }} 
                staggerTo={{ opacity: 1, y: '0px' }} 
                stagger={0.12} 
                duration={1} 
                position="+=0" />
             </Timeline> */}
         </LazyLoadImage>
    
          {/* <Image src={props.image}></Image>   */}
          <Box py={3} px={2}>
            <Name>Token : {props.sharftToken}</Name>
            <Name>Owned : {props.owned}</Name>
            <Name>{props.title}</Name>
    
            <br/>
    
            <Option active={props.owned > 0} onClick={() => sharftNft(props.id)}>
                Sharft
            </Option>

            <Option active={props.sharftToken > 69690} onClick={() => unsharftNft(props.id)}>
                Unsharft
            </Option>

            <Option active={true} onClick={() => trade(props.id, "bogSwap")}>
                BogSwap
            </Option>
            <Option active={true} onClick={() => trade(props.id, "pooCoin")}>
                PooCoin
            </Option>
          </Box>
        </SmallCard>
    )
    
    useEffect(() => {
        if(account && supportedChain(chainId)) {
            const getBalance = async () => {
                const contract = new FactoryService(library, account, chainId!);
                const sharftTokenContract = new SharftService(library, account, chainId!);
                // BSC
                if(chainId == 56){
                  const common1 = await contract.balanceOf("56042"); 
                  const common2 = await contract.balanceOf("170");
                  setCommonUpbnb1Size(common1.toNumber());
                  setCommonUpbnb2Size(common2.toNumber());

                  const common1SharftToken = await sharftTokenContract.getBalance("56042");
                  const common2SharftToken = await sharftTokenContract.getBalance("170");
                  setCommonUpbnb1SharftTokenSize(getBalanceNumber(new BigNumber(common1SharftToken.toString())));
                  setCommonUpbnb2SharftTokenSize(getBalanceNumber(new BigNumber(common2SharftToken.toString())));

                  const rare1 = await contract.balanceOf("56069");
                  const rare2 = await contract.balanceOf("70");
                  setRareUpbnb1Size(rare1.toNumber());
                  setRareUpbnb2Size(rare2.toNumber());

                  const rare1SharftToken = await sharftTokenContract.getBalance("56069");
                  const rare2SharftToken = await sharftTokenContract.getBalance("70");
                  setRareUpbnb1SharftTokenSize(getBalanceNumber(new BigNumber(rare1SharftToken.toString())));
                  setRareUpbnb2SharftTokenSize(getBalanceNumber(new BigNumber(rare2SharftToken.toString())));
                  
                  const commonUpcake1 = await contract.balanceOf("56142");
                  const commonUpcake1SharftToken = await sharftTokenContract.getBalance("56142");
                  setCommonUpcake1Size(commonUpcake1.toNumber());
                  setCommonUpcake1SharftTokenSize(getBalanceNumber(new BigNumber(commonUpcake1SharftToken.toString())));

                  const rareUpcake1 = await contract.balanceOf("56169");
                  const rareUpcake1SharftToken = await sharftTokenContract.getBalance("56169");
                  setRareUpcake1Size(rareUpcake1.toNumber());
                  setRareUpcake1SharftTokenSize(getBalanceNumber(new BigNumber(rareUpcake1SharftToken.toString())));

                  const otherUpcake1 = await contract.balanceOf("56269"); 
                  const otherUpcake1SharftToken = await sharftTokenContract.getBalance("56269");
                  setOtherUpcake1Size(otherUpcake1.toNumber());
                  setOtherUpcake1SharftTokenSize(getBalanceNumber(new BigNumber(otherUpcake1SharftToken.toString())))
                }
                else if(chainId == 137){
                  const common1 = await contract.balanceOf("169"); //upMatic Flying Carpet
                  setCommonUpmatic1Size(common1.toNumber());
                  const rare1 = await contract.balanceOf("69"); //upMatic Flying Carpet
                  setRareUpmatic1Size(rare1.toNumber());

                  const commonUpmatic1SharftToken = await sharftTokenContract.getBalance("169");
                  setCommonUpmatic1SharftTokenSize(getBalanceNumber(new BigNumber(commonUpmatic1SharftToken.toString())));

                  const rareUpmatic1SharftToken = await sharftTokenContract.getBalance("69");
                  setRareUpmatic1SharftTokenSize(getBalanceNumber(new BigNumber(rareUpmatic1SharftToken.toString())));

                }
                // else if(chainId == 1){
                //   // const common1 = await contract.balanceOf("0");
                //   const common2 = await contract.balanceOf("169");

                //   setCommon1Size(0);
                //   setCommon2Size(common2.toNumber());

                //   setRare1Size(0);
                //   setRare2Size(0);
                // }
            };
            getBalance();
            
            if(chainId == 56){
              setCommonUpbnb1ImageUrl(Common1);
              setCommonUpbnb2ImageUrl(Common2);
              setRareUpbnb1ImageUrl(Rare1);
              setRareUpbnb2ImageUrl(Rare2);
              setCommonUpcake1ImageUrl(Common1Cake);
              setRareUpcake1ImageUrl(Rare1Cake);
              setOtherUpcake1ImageUrl(Other1Cake);
            }
            else if(chainId == 137){
              setCommonUpmatic1ImageUrl(Common1Matic);
              setRareUpmatic1ImageUrl(Rare1Matic);
            }
            // else if(chainId == 1){
            //   setCommon1ImageUrl(Common1);
            //   setCommon2ImageUrl(Common2);
            //   setRare1ImageUrl(Rare1);
            //   setRare2ImageUrl(Rare2);
            // }
        }
    },  [library, account, chainId]);

    return (
        <PageWrapper>
            <PrimaryCard width="auto" margin="auto">
                <CardContent>
                  <Title>
                    SHARFT Market
                  </Title>

                  {(() => {
                    if (account){
                        // BSC 
                        if(chainId == 56){
                          return (
                              <NFTWrapper>
                                    <NFTCard title={"CZ Pixel Art (Common)"} image={commonUpbnb1ImageUrl} owned={commonUpbnb1Size} sharftToken={commonUpbnb1SharftTokenSize} id={"56042"}>
                                    </NFTCard>

                                    <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpbnb2ImageUrl} owned={commonUpbnb2Size} sharftToken={commonUpbnb2SharftTokenSize} id={"170"}>
                                    </NFTCard>
                                    
                                    <NFTCard title={"CZ Pixel Art (Rare)"} image={rareUpbnb1ImageUrl} owned={rareUpbnb1Size} sharftToken={rareUpbnb1SharftTokenSize} id={"56069"}>                                        
                                    </NFTCard>

                                    <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpbnb2ImageUrl} owned={rareUpbnb2Size} sharftToken={rareUpbnb2SharftTokenSize} id={"70"}>
                                    </NFTCard>

                                    <NFTCard title={"Cake Pixel Art (Common)"} image={commonUpcake1ImageUrl} owned={commonUpcake1Size} sharftToken={commonUpcake1SharftTokenSize} id={"56142"}>
                                    </NFTCard>

                                    <NFTCard title={"Cake Pixel Art (Rare)"} image={rareUpcake1ImageUrl} owned={rareUpcake1Size} sharftToken={rareUpcake1SharftTokenSize} id={"56169"}>                                        
                                    </NFTCard>

                                    <NFTCard title={"Space Art"} image={otherUpcake1ImageUrl} owned={otherUpcake1Size} sharftToken={otherUpcake1SharftTokenSize} id={"56269"}>                                        
                                    </NFTCard>

                              </NFTWrapper>
                          )
                        }
                        else if(chainId == 137){
                          return (
                              <NFTWrapper>
                                    <NFTCard title={"Flying Carpet Art (Common)"} image={commonUpmatic1ImageUrl} owned={commonUpmatic1Size} sharftToken={commonUpmatic1SharftTokenSize} id={"69"}>
                                    </NFTCard>

                                    <NFTCard title={"Flying Carpet Art (Rare)"} image={rareUpmatic1ImageUrl} owned={rareUpmatic1Size} sharftToken={rareUpmatic1SharftTokenSize} id={"169"}>
                                    </NFTCard>
                              </NFTWrapper>
                          )
                        }
                        // else if(chainId == 1){
                        //   return (
                        //     <div>
                        //       <div>
                        //         <label>Common Art</label>
                        //         <NFTWrapper>
                        //                 <NFTCard title={"Flying Carpet Art"} image={common2ImageUrl} owned={common2Size}>
                        //                 </NFTCard>
                        //           </NFTWrapper>
                        //       </div>
                        //     </div>
                        //   )
                        // }
                        else{
                          return <Title>Chain not supported</Title>
                        }
                    } else { 
                        return (<Text>{'Connect to a wallet'}</Text>)
                    }
                    
                    return null;
                  })()}
                </CardContent>
            </PrimaryCard>

            <SharftModal
              modalOpen={modalSharftOpen}
              toggleInfoModal={toggleSharftModal}
              artId={artId}
            />

            <UnsharftModal
              modalOpen={modalUnsharftOpen}
              toggleInfoModal={toggleUnsharftModal}
              artId={artId}
            />
        </PageWrapper>
    )
}