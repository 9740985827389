import React, { useState, useEffect, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useWeb3React } from "@web3-react/core"

import { ReactComponent as Close } from '../../assets/images/x.svg'
import Modal from '../Modal'
import { ButtonPrimary, PendingContent } from "../Button";
import { darken, lighten } from 'polished'
import { TYPE } from '../../theme'
import NumericalInput from '../../components/NumericalInput'
import { RowBetween } from '../../components/Row'
import { supportedChain } from "../../utils"
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { SharftService } from '../../services/SharftService'
import { FactoryService } from '../../services/FactoryService'
import TransactionCompletedModal from "../../components/TransactionCompletedModal"
import { ErrorMessage } from "../../components/ErrorMessage"
import { extractErrorMessage } from "../../utils/extractErrorMessage"


// const loadingFlash = keyframes`
//   0% {
//       background-position: -250px;
//   }
//   100% {
//       background-position: calc(100% + 250px);
//   }
// `

// const Blurb = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: center;
//   justify-content: center;
//   flex-wrap: wrap;
//   margin-top: 2rem;
//   margin-bottom: 2rem;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     margin: 1rem;
//     font-size: 12px;
//   `};
// `
// const CarouselButton = styled(ButtonPrimary)`
//   font-weight: 400;
//   margin: 0.5rem;
// `

const ButtonWrapper = styled.div`
  /* background-color: ${({ theme }) => theme.bg2}; */
  padding: 0.5rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`
const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

// const HeaderRow = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap};
//   padding: 1rem 1rem;
//   font-weight: 500;
//   color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     padding: 1rem;
//   `};
// `

// const HoverText = styled.div`
//   :hover {
//     cursor: pointer;
//   }
// `

// const Skeleton = styled.div`
//   width: 100%;
//   height: 8rem;
//   background-color: rgb(240, 240, 240);
//   border-radius: 5px;
//   margin-bottom: 1rem;

//   &::before {
//     content: '';
//     display: block;
//     width: 100%;
//     height: 100%;
//     border-radius: 5px;
//     background: linear-gradient(
//       90deg,
//       rgb(240, 240, 240) 0px,
//       #f9f9f9 calc(50% - 25px),
//       #f9f9f9 calc(50% + 25px),
//       rgb(240, 240, 240) 100%
//     );
//     background-size: 35%;
//     background-position: 0%;
//     background-repeat: no-repeat;
//     animation: ${loadingFlash} 1.5s infinite linear;
//   }
// `
const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  border: 1px solid ${({ theme }) => lighten(0.04, theme.bg2)};
  background-color: ${({ theme }) => theme.bg1};
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary1};
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 0.5rem;
  font-size: 0.875rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.text1};
  :hover:not([disabled]) {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus:not([disabled]) {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  :disabled {
    cursor: auto;
    background-color: ${({ theme }) => theme.bg3};
    border: 1px solid ${({ theme }) => theme.bg4};
    color: ${({ theme }) => theme.bg5};
  }
  

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

const Row = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  display: flex;
  justify-content: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 0.875rem;
  `};
`

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 0.875rem;
  `};
`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 1.5em;
    letter-spacing:0.025em;
    text-align: center;
`

// const ButtonsWrapper = styled.div`
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 1.5em;
//     align-items: center;
// `

interface InfoModalProps {
    modalOpen: boolean,
    toggleInfoModal: () => void,
    artId: string
}

enum SharftStatus {
  None,
  Approving,
  Approved,
  Sharfting,
  Sharfted
}



export default function SharftModal({
    modalOpen,
    toggleInfoModal,
    artId
} : InfoModalProps) {
    const { account, library, chainId } = useWeb3React();
    const [ image, setImage ] = useState("");
    const [ max, setMax ] = useState(0);
    const [ inputMax, setInputMax ] = useState(0);
    const [transactionHash, setTransactionHash] = useState<string>("");
    const [completedAction, setCompletedAction] = useState("");
    const [error, setError] = useState("");
    const [status, setStatus] = useState<SharftStatus>(SharftStatus.None);
    const [isApproved, setIsApproved] = useState<boolean>(false);

    const theme = useContext(ThemeContext)

    const onUserOutput = (val:any) => {
      setInputMax(val)
    }
  
    const onMax =  async() => {
      setInputMax(max)
    }

    useEffect(() => {
        const getIsApprove = async (art:string) => {
            const service = new FactoryService(library, account!, chainId!);
            const approved = await service.isApprovedForAllSharft(art);
            setIsApproved(approved);
            if(approved) {
                setStatus(SharftStatus.Approved);
            }
            else{
              setStatus(SharftStatus.None);
            }
        }
        if(account && supportedChain(chainId)) {
            if(artId != null && artId != ""){
              getIsApprove(artId);
            }
        }
    }, [artId, library, account, chainId])

    useEffect(() => {
      if(account && supportedChain(chainId)) {
        const sharftTokenContract = new SharftService(library, account, chainId!);
        const factoryContract = new FactoryService(library, account, chainId!);
        setImage(sharftTokenContract.getImageUrl(artId))

        const getBalance = async (art:string) => {          
          let max = await factoryContract.balanceOf(art);
          setMax(max.toNumber())
        }
        if(artId != null && artId != ""){
          getBalance(artId);
        }
      }
    },  [artId]);

const approve = async () => {
  try {
      setStatus(SharftStatus.Approving);
      const service = new FactoryService(library, account!, chainId!);
      const txResponse = await service.approveSharft(artId);
      if (txResponse) {
          const receipt = await txResponse.wait()
          if (receipt?.status === 1) {
              setTransactionHash(receipt.transactionHash);
              setStatus(SharftStatus.Approved);
              setIsApproved(true);
          }
          else {
              setError("Transaction Failed");
              setStatus(SharftStatus.None);
          }
      }
  }
  catch (e) {
      console.log(e);
      const errorMessage = extractErrorMessage(e);
      if(errorMessage) {
          setError(errorMessage);
      }
      setStatus(SharftStatus.None);
  }
}

const doSharft = async() => {
  setError("");

  try {
      setCompletedAction(`NFT Sharfted`);
      setStatus(SharftStatus.Sharfting);

      const service = new SharftService(library, account!, chainId!)
      console.log("Shatter nft : "+artId);
      const txResponse = await service.shatterNFT(artId, inputMax)
      if (txResponse) {
          const receipt = await txResponse.wait()
          if (receipt?.status === 1) {
              setTransactionHash(receipt.transactionHash);
              setStatus(SharftStatus.Sharfted);
              setInputMax(0);
          }
          else {
              setError("Transaction Failed");
              setStatus(SharftStatus.None);
          }
      }
  }
  catch (e) {
      console.log(e)
      const errorMessage = extractErrorMessage(e);
      if(errorMessage) {
          setError(errorMessage);
      }
      setStatus(SharftStatus.None)
  }
}

// function approveSharft() {}

function getModalContent() {
  const hideInput = false;
    return (
        <UpperSection>
            <CloseIcon onClick={toggleInfoModal}>
                <CloseColor />
            </CloseIcon>
                <ContentWrapper>
                        <Title>SHARFT</Title>
                        {error ? <ErrorMessage error={error} /> : null}

                        <Row>
                        <LazyLoadImage
                          alt={"NFT"}
                          effect="blur"
                          src={image}
                          width={150}
                          height={200} >
                        </LazyLoadImage>
                        </Row>
                        <Container hideInput={hideInput}>
                          <LabelRow>
                              <RowBetween>
                              <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
                                  {"NFT Amount"}
                              </TYPE.body>
                              <TYPE.body
                                  color={ theme.text2}
                                  fontWeight={500}
                                  fontSize={14}
                                  style={{ display: 'inline', cursor: 'pointer' }}
                                  >
                                  {"NFT Owned"}: {max}
                              </TYPE.body>
                              </RowBetween>
                          </LabelRow>

                          <InputRow>
                          <>
                              <NumericalInput
                              className="token-amount-input"
                              value={inputMax}
                              onUserInput={val => onUserOutput(val)}
                              onSubmit={() => {}}              
                              />
                              <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
                              NFT
                          </>
                          </InputRow>
                      </Container>
                    <ButtonWrapper>

                      { isApproved ?
                      <ButtonPrimary disabled={status === SharftStatus.Sharfting || !supportedChain(chainId)} onClick={doSharft}>
                          {status === SharftStatus.Sharfting
                              ? <PendingContent text={"Sharfting..."}/>
                              : "SHARFT"
                          }
                      </ButtonPrimary>
                      : 

                      <ButtonPrimary onClick={approve} disabled={status === SharftStatus.Approving || !supportedChain(chainId)}>
                          {status === SharftStatus.Approving 
                              ? <PendingContent text={"Approving..."}/>
                              : status === SharftStatus.Approved ? "SHARFT" : "Approve"
                          }
                      </ButtonPrimary>
                      }
                    </ButtonWrapper>
                </ContentWrapper>
        </UpperSection>
    )
    }
    return (
    <Modal isOpen={modalOpen} onDismiss={toggleInfoModal} minHeight={false} maxHeight={90}>
        <TransactionCompletedModal title={completedAction} hash={transactionHash} isOpen={status === SharftStatus.Sharfted} onDismiss={() => {setStatus(SharftStatus.None); window.location.reload();}} />
        <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
)
}