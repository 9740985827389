import { Contract } from '@ethersproject/contracts'
// import airdropAbi from '../constants/abis/airdrop.json'
import airdropBscAbi from '../constants/abis/airdropBsc.json'
import { Web3Provider } from '@ethersproject/providers'
// import { AIRDROP_ADDRESS } from '../constants'
// import { GasStationService } from './GasStationService';

export class AirdropServiceV1 {
    private contract: Contract;
    private account: string;
    // private gasStation: GasStationService;

    constructor(library: Web3Provider, account: string, chainId: number, airdropAddress: string) {
        this.account = account;
        const signer = library.getSigner(account).connectUnchecked()

        this.contract = new Contract(airdropAddress, airdropBscAbi, signer);
        if(chainId == 56) {
            this.contract = new Contract(airdropAddress, airdropBscAbi, signer);
        }
        else if(chainId == 137){
            this.contract = new Contract(airdropAddress, airdropBscAbi, signer);
        }
        // this.gasStation = new GasStationService(chainId);
    }

    public async claim() {
        // let estimation = await this.gasStation.getGas();
        // let options = { gasLimit: 100000, gasPrice: estimation };
        return await this.contract.claimNFT();
    }

    public async isEligible() {
        const isEligible = await this.contract.eligibleUsers(this.account);
        return isEligible;
    }
}